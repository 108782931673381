// This component displays the title data when making bookings and then fixtures
import React from "react";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
const dayjs = require("dayjs"); // for manipulating dates

const useStyles = makeStyles((theme) => ({
  titleBoxHolder: {
    // this adjusts its size to fit the contents
    borderWidth: "thin",
    borderStyle: "solid",
    borderRadius: "5px",
    padding: "10px",
    marginTop: "10px",
    marginBottom: "10px",
    backgroundColor: "#e1f1df",
    width: "90%",
    [theme.breakpoints.up("md")]: {
      width: "auto",
    },
  },
  courseDateBox: {
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  courseDateItem: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  heading: {
    textAlign: "left",
  },
  cartTitle: {
    fontWeight: "bold",
  },
  spaceRight: {
    paddingRight: "15px",
  },
}));

// This is the title box (its extracted because needed on screen and for print)
function TitleBox({ bookingData, attendeeCount, cartCount }) {
  const classes = useStyles();
  return (
    <Box // this box - with rounded border - surrounds <TitelBox>
      display="flex"
      flexDirection="column"
      className={classes.titleBoxHolder}
    >
      <Box display="flex" className={classes.courseDateBox}>
        <Box
          textAlign="center"
          //sx={{ border: "1px dashed grey" }}
          className={classes.courseDateItem}
        >
          <Typography variant="h5">
            {dayjs(bookingData.eventDate).format("ddd, DD MMM YYYY")}
          </Typography>
        </Box>
        <Box
          textAlign="center"
          //sx={{ border: "1px dashed grey" }}
          className={classes.courseDateItem}
        >
          <Typography variant="h5">{bookingData.course}</Typography>
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        style={{ borderBottom: "1px dotted black", marginBottom: "2px" }}
      >
        <Typography className={classes.heading} variant="body1">
          <span className={classes.cartTitle}>Tee Off : </span>
          <span style={{ color: "red", paddingRight: "10px" }}>{bookingData.teeOffTime}</span>
        </Typography>
        <Typography className={classes.heading} variant="body1">
          <span className={classes.cartTitle}>Comp:</span>{" "}
          <span style={{ paddingRight: "10px" }}>{bookingData.competition}</span>
        </Typography>
        <Typography className={classes.heading} variant="body1">
          <span className={classes.cartTitle}>Sponsor:</span> {bookingData.sponsor}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography className={classes.heading} variant="body1">
          <span className={classes.cartTitle}>Registered : </span>({attendeeCount})
        </Typography>
        <Typography className={classes.heading} variant="body1">
          <span className={classes.cartTitle}>Carts req'd:</span> ({cartCount})
        </Typography>
      </Box>
    </Box>
  );
} // end TitleBox()

export default TitleBox;
