import React, { useState, useEffect } from "react";
import LoggedInContext from "./components/loggedInContext.js";
import { makeStyles } from "@material-ui/styles";
import Header from "./components/header";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { menuMembers, menuPublic } from "./menus.js";
import { v4 as uuidv4 } from "uuid";
import * as Comps from "./components/stubs";
import MakeTimesheet from "./routes/makeTimesheet.js";
import Login from "./routes/login.js";
import Logout from "./routes/logout.js";
import Home from "./routes/home.js";
import Minutes from "./routes/minutes.js";
import Reports from "./routes/reports.js";
import BookingSheet from "./routes/bookingSheet.js";
import { getWithExpiry } from "./utils/localStorage.js";
import DisplayReport from "./components/displayReport.js";
import LoginMiClub from "./routes/loginMiClub.js";

// makestyles automatically passes the current theme as parameter
const useStyles = makeStyles((theme) => ({
  outsideDiv: {
    //Roboto for everything. MaterialUI uses this for Typography anyhow.
    fontFamily: "Roboto, Arial, sand-serif",
    position: "relative",
    marginTop: "160px",
    [theme.breakpoints.up("md")]: {
      marginTop: "200px",
    },
  },
  containerRoot: {
    paddingLeft: "5px",
    paddingRight: "5px",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "24px",
      paddingRight: "24px",
    },
  },
  paper: {
    padding: "5px",
    [theme.breakpoints.up("sm")]: {
      padding: "30px",
    },
  },
}));

// This is the full list of components so we can access them
// with a string index.
const componentArray = {
  Home: Home, // is in src/routes
  "About Us": Comps.AboutUs,
  "In Memorium": Comps.InMemorium,
  Objectives: Comps.Objectives,
  "Contact Us": Comps.ContactUs,
  "Membership Application": Comps.MembershipApply,
  "Fixtures - Booking": LoginMiClub, // is in src/routes
  //"Fixtures - Reports": FixturesNew2,
  "Members Login": Login, // is in src/routes
  Notices: Comps.Notices,
  //"Club Championships": Comps.ClubChampionships,
  "Club Championships": LoginMiClub,
  //"Order Of Merit": Comps.OrderOfMerit,
  "Order Of Merit": LoginMiClub,
  //Eclectic: Comps.Eclectic,
  Eclectic: LoginMiClub,
  "Match Play": Comps.MatchPlay,
  "Bent Putter": Comps.BentPutter,
  "Monthly Medals": Comps.MonthlyMedals,
  //"Members Details": Comps.MembersDetails, // is in src/routes
  "Members Details": LoginMiClub, // is in src/routes
  "Code Of Conduct": Comps.CodeOfConduct, // is in src/routes
  Constitution: Comps.Constitution,
  "By Laws": Comps.ByLaws,
  Minutes: Minutes,
  Reports: Reports,
  "Rules Explained": Comps.RulesExplained,
  "Championship Rules": Comps.ChampionshipRules,
  "Match Play Rules": Comps.MatchPlayRules,
  "Medal Of Medals Rules": Comps.MedalOfMedalsRules,
  "Order Of Merit Rules": Comps.OrderOfMeritRules,
  "Eclectic Rules": Comps.EclecticRules,
  "Office Bearers And Champions": Comps.OfficeBearersAndChampions,
  "Annual Awards": Comps.AnnualAwards,
  "Annual Trophies": Comps.AnnualTrophies,
  "Other Boards": Comps.OtherBoards,
  "Mugs Gallery": Comps.MugsGallery,
  Logout: Logout, // is in src/routes
};

// we want to make a menu system with submenues
// this will be described by an object containing and array of menu item objects
// Each menu item can have an array of submenu items objects
// This is used to construct the menu in header.js and the routes in App.js

function App() {
  // make up an array of routes
  // We put all routes here irrespective of whether public or members

  // Note: there is only one user/password being golfa/bentputter
  // login.js and logout.js set the logged in state and save in local storage
  //const [loggedIn, setLoggedIn] = useState(getWithExpiry("userName") ? true : false); // true if logged in

  // instead of using localStorge, check the logged in status via the
  // "/getLoggedInMember" api

  const [isLoaded, setIsLoaded] = useState(false);
  const [loggedIn, setLoggedIn] = useState({});

  // This replaces above login code for now
  useEffect(() => {
    let localStorageLogin = getWithExpiry("loggedIn");
    if (localStorageLogin) {
      setLoggedIn({
        status: true,
        user: "golfa",
      });
    } else {
      setLoggedIn({
        status: false,
        user: "public",
      });
    }
    setIsLoaded(true);
  }, []);

  function makeComponent(component) {
    // note: the tagname here cant be a string unless its a DOM node
    const TagName = component;
    return <TagName></TagName>;
  }

  // make an array of routes to inject into <BrowserRouter>
  let routesArray = [];

  // make an array of routes to inject into <BrowserRouter>
  function makeRoutes(menuArray) {
    menuArray.forEach((routeInfo) => {
      // each array element will either be a route or a submenu
      if (routeInfo.component) {
        // its a route
        routesArray.push(
          <Route
            key={uuidv4()}
            exact
            path={`/${routeInfo.component.replace(/\s/g, "")}`}
            element={makeComponent(componentArray[routeInfo.component])}
          />
        );
      } else if (routeInfo.submenu) {
        // its a submenu
        let topLevelRoute = `/${routeInfo.submenu.replace(/\s/g, "")}`;
        routeInfo.submenuItems.forEach((routeInfo) => {
          if (routeInfo.component) {
            routesArray.push(
              <Route
                key={uuidv4()}
                exact
                path={`${topLevelRoute}/${routeInfo.component.replace(
                  /\s/g,
                  ""
                )}`}
                element={makeComponent(componentArray[routeInfo.component])}
              />
            );
          }
        });
      }
    });
    //console.log("routes", routesArray);
  }

  // What to display when a use types an unknown URL
  function NoMatch() {
    let location = useLocation();
    return (
      <div>
        <h1>Error 404</h1>
        <h3>
          No match for <code>{location.pathname}</code>
        </h3>
      </div>
    );
  }
  const classes = useStyles();

  // make routes for either members (logged in) or public (not logged in)
  console.log("APP logged in; ", loggedIn);
  loggedIn.status ? makeRoutes(menuMembers) : makeRoutes(menuPublic);

  return !isLoaded ? (
    <div>Loading....</div>
  ) : (
    <LoggedInContext.Provider value={{ loggedIn, setLoggedIn }}>
      <BrowserRouter>
        <div className={classes.outsideDiv}>
          <Header
            itemSelected={"itemSelected"}
            setItemSelected={"setItemSelected"}
            updateSelection={"updateSelection"}
            pagesMap={"pagesMap"}
          />
          <Container
            classes={{
              root: classes.containerRoot, // this is to reduce padding at the lower screen sizes
            }}
          >
            <Paper id="paperBase" elevation={6} className={classes.paper}>
              <Routes>
                {/* All routes are exact match except '/' as the last route as catch-all */}
                {routesArray}
                <Route key={uuidv4()} exact path="/Home" element={<Home />} />
                <Route
                  key={uuidv4()}
                  exact
                  path="/displayMinutes/:fileName"
                  element={<DisplayReport reportType="Minutes" />}
                />
                <Route
                  key={uuidv4()}
                  exact
                  path="/displayReports/:fileName"
                  element={<DisplayReport reportType="Reports" />}
                />
                <Route key={uuidv4()} path="/" element={<Home />} />
                <Route key={uuidv4()} path="*" element={<NoMatch />} />
              </Routes>
            </Paper>
          </Container>
        </div>
      </BrowserRouter>
    </LoggedInContext.Provider>
  );
}
export default App;
