import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { useParams, Navigate } from "react-router-dom";
import axios from "axios";
import { cloneDeep } from "lodash"; // for deep clone of array of objects
import ReactToPrint from "react-to-print";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import { Icon } from "@mdi/react";
import { mdiGolfCart } from "@mdi/js";
import { mdiCheck } from "@mdi/js";

import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ADDRESSES from "../IPandPorts.js";
import LoggedInContext from "../components/loggedInContext.js";
import { ADMIN } from "../constants";
import { handleAxiosError } from "../utils/handleAxiosError.js";
import DisplayAttendeeList from "./bookingSheetSubs/displayAttendeeList.js";
import SelectMember from "../components/selectMember.js";
import SelectCart from "./bookingSheetSubs/selectCart.js";
import TitleBox from "./titleBox.js";

import { v4 as uuidv4 } from "uuid";

/**
 *
 * <BookingSheet2> displays the booked members and
 * provides a <Dialog> containing <SelectMember> and <SelectCart>
 *    <SelectMember> allows selection of a member or to type in a visitor + golflink
 *       The callback to selectMember is saveSelectedMemberCallback() which just saves
 *       the selected player to state.
 *    <selectCart> enables select of cart and pog.
 *       Its confirm button is enabled only when its passed a membername
 *       The callback to <selectCart> is saveNewAttendeeCallback()
 *       which saves the new player and options to the bookingSheet
 *    <SelectMember> has a radio group to allow user to either pick a member from a list
 *       or type in a visitors name.
 *       If a member is selected the name is passed back to <bookingSheet> and saved in
 *       [selectedMember] state
 *       If a visitor is typed in his name is name is prefixed with (V).
 *       When the "Done" button is pressed the name is passed back to <bookingSheet> and saved in
 *       [selectedMember] state
 *
 * provides a <dialog> containing <SelectCart> only to allow editing of the options
 *       It receives a memberName as parameter
 *       The callback to <selectCart> is saveSelectionCallback()
 *       which updates the bookingSHeet entry
 *
 * The dislayed list is in 2 columns, with players in the right column
 * only if they are sharing a lart with a player in the loft column.
 *
 * a [ book ] link appears in all empty positions
 * [ book ] in the left column books a new player
 * [ book ] in the right column books a new player to share with the left player
 * [ match partner] (admin only) moves a cart player on the left to the right
 *    thereby sharing the cart.
 *
 * When [ book ] is pressed a <Dialog> opens which displays <SelectMember> and then <SelectCart>
 * The state [selectedMember] should contain the memberName and cartNeeds after the Dialog closes.
 * Unless "cancel" was pressed in the <Dialog>, the new selectedMember is then saved to
 * BookingSheet.attendees[] in Mongo.
 * We then save [selectedMember] to [prevSelectedMember] so it can be displayed, and we
 * clear [selectedMember] for the next <Dialog>
 *
 * If [ book ] is pressed in the right column we also need to set and save the sharingWith
 * of [selectedMember] and further set the sharingWidth of the member in the left column.
 * This is how cart sharing is implemented.
 *
 *
 * <BookingSheet> is routed to from <DisplayFixture>
 * as route:"/bookingSheet:fixtureID"
 *  <BookingSHeet> gets fixture id as a parameter - useParams()
 *  We read the fixture data - with api "/getFixture" - useEffect()
 *  The fixtures comes populated with the bookingSheet from that api.
 *  bookingsheet contains an array of members and their cart preferences
 *
 * Notes on state variables and interactions of <BookingSheet> with
 *  <RegisterAnyMember>, <SelectMember>, <SelectCart> and <DisplayAttendeeList>
 *  <BookingSheets> is in charge of attendes[]
 *    const [attendees, setAttendees] = useState([]);
 *    This list is polulated by useEffect() which reads the list from the bookingSheet
 *    document
 *  <SelectCart> is "called" directly by <BookingSheet> when the logged in member
 *  is not "admin" and is not registered. It is passed parameters as in..
 *  <SelectCart attendee={} handleRegisterMember={} > where attendee is the logged in user
 *  and handleRegisterMember saves the attendee to the attendees[] and adds to the bookingSheet
 *  document in mongo.
 *
 *  <SelectMember> reads the full list of members from Mongo "members" and
 *  makes a composite memberList[]. It is also passed attendees[] from <BookingSheet>
 *  and it uses this to blamk out from the selection list, any members who are already selected.
 *
 */
// These are used to define the type of ID update needes
const ADD_PARTNER_CART_ID = 1; // all hardcoded for now (server-side also)
const CHANGE_TO_SHARED = 2;
const CHANGE_FROM_SHARED = 3;
const CART_NEEDS_UNCHANGED = 4;
//const CHANGE_FLIGHT_TIME =5; // used in <MakeTimeSheet>

const useStyles = makeStyles((theme) => ({
  centeringContainer: {
    border: "1px solid red",
    minHeight: "500px",
  },
  attendeeListContainer: {
    marginTop: "15px",
    marginBottom: "15px",
    width: "95%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  memberName: { paddingLeft: "5px" },
  backButton: {
    display: "inline-block",
    textDecoration: "none",
    color: "black",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  backIcon: {
    verticalAlign: "-6px",
  },
  backJustify: {
    display: "flex",
    justifyContent: "flex-end",
  },
  dialogBox: {
    backgroundColor: "#d5e4d7", // light green
    padding: "15px",
    border: "2px solid black",
    borderRadius: "5px",
  },
  cartDialogBox: {
    backgroundColor: "#d5e4d7", // light green
    padding: "25px",
    border: "2px solid black",
    borderRadius: "5px",
  },
  selectMemberBox: {
    backgroundColor: "white",
  },
  printButton: {
    border: "1px black solid",
    color: "white",
    textAlign: "left",
    fontSize: "16px",
    cursor: "pointer",
    backgroundColor: "#73bb76",
  },
  controlBox: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  controlPane: {
    width: "100%",
    justifyContent: "space-between",
  },
}));

// NOTE: we cant use getenv with React - so loading from IPandPorts.js file
const ARMY_NODE_SERVER_HOST = ADDRESSES.IP;
const ARMY_NODE_SERVER_PORT = ADDRESSES.APIPort;

function BookingSheet() {
  // console.log("booking sheet fired...");

  const membersListRef = useRef(); // used for printing
  const cartBuddyListRef = useRef(); // used for printing
  const { fixtureId } = useParams(); //as appearing at the end of the URL
  const classes = useStyles();
  const { loggedIn } = useContext(LoggedInContext); //{status: true|false, user: name}

  // This stores data about the fixture including booking sheet id
  const [bookingData, setBookingData] = useState({
    fixtureName: null,
    fixtureDate: null,
    bookingSheetId: null,
  });

  const [attendees, setAttendees] = useState([]); // list of registered attendees
  const [attendeeRows, setAttendeeRows] = useState([]); // in 2 cols ready for display
  const [unFullCarts, setUnFullCarts] = useState([]); // carts not filled (ie left pos filled only)
  const [fixtureIsLoaded, setFixtureIsLoaded] = useState(false); // Loading indicator

  // use this to force reread of attendees[] from Mongo when changes occur
  // ie Add, delete or update transactions via the UI
  const [attendeesChanged, setAttendeesChanged] = useState(true); // we must read on first entry

  //This state controls the booking dialogs
  const [openDialog, setOpenDialog] = useState(false); // dialog closed by default

  // This state controls the Dialog used to fill a cart - admin only function
  const [openCartDialog, setOpenCartDialog] = useState(false); // dialog closed by default

  // This state controls the Dialog that allows a member to change his cart needs
  const [openCartOptionDialog, setOpenCartOptionDialog] = useState(false);

  // set:     when "[ Book ]" click on the right opens the <Dialog>
  // cleared: when "[ Book ]" click on the left opens the <Dialog>
  const [leftAttendee, setLeftAttendee] = useState({});
  const [leftCartId, setLeftCartId] = useState(null); // is redundant but still used

  // This is set in <SelectMember> and updated by <SelectCart>
  const [selectedMember, setSelectedMember] = useState({
    memberName: "",
    cartNeeds: "walk",
    potOfGold: false,
  });

  // This is used to display the last registered member if any
  //const [registeredAttendee, setRegisteredAttendee] = useState({
  //  memberName: "",
  //  cartNeeds: "walk",
  //});

  const [message, setMessage] = useState({ title: "Changes:", text: "none" });

  // Read the fixture with polulated BookingSheet
  useEffect(() => {
    //console.log(`<BookingSheet> fetching fixture data for fixture ${fixtureId}`);
    // Note: Axios makes a query URL if you set the params object as shown below
    // This creates an API call like /getBookingSheet?id=5fb10732d147ed4358eb55a4
    //setFixtureIsLoaded(false);
    // First define fetchData() function
    const fetchData = async () => {
      try {
        // get the fixture from MongoDB WITH bookingSheet populated
        //console.log(`Getting fixture ${fixtureId}  from MongoDB`);
        const FIXTURE_URL = `http://${ARMY_NODE_SERVER_HOST}:${ARMY_NODE_SERVER_PORT}/fixtures/findById`;
        const response = await axios({
          method: "GET",
          withCredentials: true,
          url: FIXTURE_URL,
          params: { id: fixtureId },
        });

        const { fixture } = response.data; //destructuring
        //console.log(Array.isArray(fixture.bookingSheetRef.attendees));
        //save the fixture data in state
        setBookingData({
          course: fixture.course,
          eventDate: fixture.eventDate,
          bookingSheetId: fixture.bookingSheetRef._id,
          competition: fixture.competition,
          sponsor: fixture.sponsor,
          teeOffTime: fixture.teeOffTime,
        });
        //console.table(fixture.bookingSheetRef.attendees);
        let sortedAttendees = sortAttendees(fixture.bookingSheetRef.attendees);
        let arrangedAttendees = arrangeAttendees(sortedAttendees);
        setAttendees(cloneDeep(fixture.bookingSheetRef.attendees));
        setAttendeeRows(cloneDeep(arrangedAttendees));
        // now make a new array of rows ready for display
        setFixtureIsLoaded(true);
      } catch (error) {
        handleAxiosError(error);
      }
    }; // end of fetchdata() definition

    // Fetch only if someone is logged in
    // otherwise we get React "can't set state on umounted" error if we have Redirected away
    if (loggedIn.status === true && attendeesChanged) {
      //console.log("Booking Sheet Fetching Data");
      fetchData().then(() => {
        setAttendeesChanged(false); // this is set true if UI add, delete or update
      }); // includes sorting
    }
  }, [fixtureId, loggedIn, attendeesChanged]);

  /** ***************************************************************
   * sorts the attendees list
   */
  function sortAttendees(unsortedAttendees) {
    /**
     * Note: the attendees list should be soted by Mongo, but dont know how to do this yet!
     */
    function compareAttendees(a, b) {
      // first sort by cart needs in order "shared", "unshared", "walk"
      if (a.cartNeeds < b.cartNeeds) return -1;
      if (a.cartNeeds > b.cartNeeds) return 1;
      // cartNeeds are the same so sort by cartID
      if (a.cartId < b.cartId) return -1;
      if (a.cartId > b.cartId) return 1;
      return 0;
    }

    // now perform the sort - but not directly on the state
    if (unsortedAttendees.length > 1) {
      const sortedAttendees = cloneDeep(unsortedAttendees);
      sortedAttendees.sort(compareAttendees);
      return sortedAttendees;
    } else {
      return unsortedAttendees;
    }
  } // end of sortAttendees

  // this function arranges the attendees by left-right with right only filled
  // if a cart is fully occupied.
  function arrangeAttendees(sortedAttendees) {
    let rows = [];
    let attendeesRow = [];

    sortedAttendees.forEach((attendee, index) => {
      if (attendeesRow.length === 0) {
        // if a new row then just save it
        attendeesRow.push(attendee);
      } else {
        // if this is a sharing row then push it
        if (
          attendeesRow[0].cartNeeds === "shared" &&
          attendee.cartNeeds === "shared" &&
          attendeesRow[0].cartId === attendee.cartId
        ) {
          attendeesRow.push(attendee);
          // the row is full
          rows.push(attendeesRow); // save it
          attendeesRow = []; // start a new row
        } else {
          // its not sharing so save and start a new row
          rows.push(attendeesRow);
          attendeesRow = []; // start a new row
          attendeesRow.push(attendee); // save it
        }
      }
      // if its the last attendee then save it
      if (index + 1 >= sortedAttendees.length && attendeesRow.length > 0) {
        rows.push(attendeesRow);
      }
    });

    // Make the cart green if "shared" and 2nd position is free
    // Make an array of carts that are not yet filled
    setUnFullCarts([]);
    rows.forEach((row) => {
      if (row.length === 1 && row[0].cartNeeds === "shared") {
        row[0].colour = "green"; // this is really just a way to say the right position in cart is empty
        setUnFullCarts((unFullCarts) => [...unFullCarts, row[0]]); // for admin to select cart buddy
      }
    });

    return rows;
  }
  /** *************** callbacks for <SelectMember> and <SelectCart> */

  // the memberName selected in <SelectMember> is saved to state
  // It is triggered by selecting a member from the drop down list in <SelectMember>
  function saveSelectedMemberCallback(memberName) {
    console.log("returned by SelectMember", memberName);
    setSelectedMember(memberName);
  }
  /** -------------------------------------------------------------------
   * The member selected above is saved along with the cart and pot of gold option
   * This callback is passed to and triggered by <SelectCart> "Confirm" Button
   */
  function saveNewAttendeeCallback(selectedMember) {
    //console.log("Selected Member in saveNewAttendeeCallback", selectedMember);
    let newAttendee = selectedMember;

    // set sharingWith and bookedBy before saving
    if (leftCartId) {
      newAttendee.cartId = leftCartId;
    } else {
      newAttendee.cartId = null;
    }
    newAttendee.bookedBy = loggedIn.user;

    console.log(newAttendee);

    // Save the new booking to the bookingSheet.attendees[] in Mongo
    saveAttendeeToBookingSheet(newAttendee).then(() => {
      setMessage({ title: "Booked:", text: newAttendee.memberName });
      setAttendeesChanged(true); // force re-read of fixture from Mongo after the transaction
      //setRegisteredAttendee(newAttendee); // remember which member was just registered
      // cleanup
      setSelectedMember({ memberName: "", cartNeeds: "walk", potOfGold: false }); // clear the selected member
    });
    setOpenDialog(false); // close the <Dialog>
  }

  // This just closes the <Dialog> and clears state
  // This is triggered by the "Cancel" button in <SelectCart>
  function cancelMemberSelection() {
    setSelectedMember({ memberName: "", cartNeeds: "walk" }); // clear the selected member
    setOpenDialog(false);
  }

  // this functions opens the selectMember/cart dialog
  // if "attendee" is passed that means its a cart share with pos 1
  // so we save the pos 1 attendee to later update it
  function handleOpenDialog(leftAttendee) {
    // Only open this Dialog if the logged in user has not already
    // exceeded his limit of 2
    //console.log(leftAttendee);
    if (loggedIn.role !== ADMIN) {
      if (bookingsCount() >= 2) {
        setMessage({ title: "Booking limit:", text: "You already have 2 bookings" });
        return;
      }
    }
    // if the booking is on the right we are sharing a cart
    // so the memberName to share with is passed as a parameter
    if (leftAttendee && leftAttendee.cartId) {
      setLeftAttendee(leftAttendee);
      setLeftCartId(leftAttendee.cartId); // save it to record the sharingWith of left and right
    } else {
      setLeftAttendee({}); // clear it
      setLeftCartId(null); // clear it
    }
    setOpenDialog(true); // actually opens <Dialog> which allows the member to be selected.
  } // end handleOpenDialog()

  // This is always opened from an empty right position
  // Opens dialog to move two "shared" players to one cart
  function handleOpenCartDialog(leftAttendee) {
    setLeftAttendee(leftAttendee);
    setLeftCartId(leftAttendee.cartId);
    setOpenCartDialog(true);
  }

  // this is called when admin presses [move] in the right column
  // and after the member to move is selected
  function memberMovedToCart(event, attendeeToMove) {
    let attendee = attendeeToMove;
    //console.log("memberMoved: ", attendee.memberName);
    //console.log("left member cartId:", leftCartId);
    attendee.cartId = leftCartId;
    // Save the new booking to the bookingSheet.attendees[] in Mongo
    let updateType = ADD_PARTNER_CART_ID;
    patchAttendeeInBookingSheet(attendee, updateType).then(() => {
      setMessage({
        title: "Moved player :",
        text: `${attendee.memberName} to share with ${leftAttendee.memberName} `,
      });
      setAttendeesChanged(true); // force re-read of fixture from Mongo after the transaction
    });

    setOpenCartDialog(false); // close the Dialog
  }

  // ***********Cart option select Dialog callbacks ************
  function handleOpenCartOptionDialog(attendee) {
    // This is fired when the cart/walker icon is clicked
    // Can be fired by by admin, the person who made the booking or the booking owner
    if (
      attendee.bookedBy === loggedIn.user ||
      loggedIn.role === ADMIN ||
      attendee.memberName === loggedIn.user
    ) {
      //console.log("clicked cart options dialog");
      //console.log(attendee);
      // below we have to make a deep copy, otherwise state will contain a shallow copy - reference
      // which will result in modifying what is displayed - not good practice
      setSelectedMember({
        memberName: attendee.memberName,
        cartNeeds: attendee.cartNeeds,
        potOfGold: attendee.potOfGold,
      });
      setOpenCartOptionDialog(true);
    }
  }
  /** -------------------------------------------------------------------
   * saveSelectionsCallback(selectedMember) is callback passed to <SelectCart>
   */
  function saveSelectionsCallback(attendee) {
    // selectedMember was set when the dialog opened
    // attendee is a new object which has the new memberName, cartNeeds and pog
    if (
      attendee.cartNeeds === selectedMember.cartNeeds &&
      attendee.potOfGold === selectedMember.potOfGold
    ) {
      // nothing has changed, so we do nothing - probably never reach here
      setMessage({
        title: "Cart needs: ",
        text: `No changes ${attendee.memberName}`,
      });
      return;
    }
    // check if we are changing to "shared" cart
    let updateType;
    if (selectedMember.cartNeeds !== "shared" && attendee.cartNeeds === "shared") {
      updateType = CHANGE_TO_SHARED;
    } else if (selectedMember.cartNeeds === "shared" && attendee.cartNeeds !== "shared") {
      updateType = CHANGE_FROM_SHARED;
    } else updateType = CART_NEEDS_UNCHANGED;

    // patchAttendeeInBookingSheet() is async so returns a promise
    patchAttendeeInBookingSheet(attendee, updateType).then(() => {
      setMessage({
        title: `${attendee.memberName} options: `,
        text: `Cart: "${attendee.cartNeeds}",  Pot Of Gold: "${attendee.potOfGold ? "Yes" : "No"}"`,
      });
      setAttendeesChanged(true); // force re-read of fixture from Mongo after the transaction
    });
    setOpenCartOptionDialog(false); // close the Dialog
  } // end saveSelectionsCallback()

  // Cancel the options dialog
  function cancelOptionSelectionCallback() {
    console.log("cancelling new options");
    setOpenCartOptionDialog(false);
  }
  /* ************************************************************** */
  // Axios function to modify and attendee record in Mongo
  // if the attendee is sharing cart with a left member, then
  // state variable "leftCartId" will have a value
  const patchAttendeeInBookingSheet = async (attendee, updateType) => {
    // This function can change the cartID if a user is moved to another "side" (key = "cartid")
    // or
    // It can change the cart options and/or the potOfGold status (key = "member options")
    //console.log("saving changes to Mongo");
    try {
      // Pass the search criteria in req.data
      //console.log(`Saving an attendee in MongoDB in bookingSHeet ${bookingData.bookingSheetId}`);
      const SAVE_ATTENDEE_URL = `http://${ARMY_NODE_SERVER_HOST}:${ARMY_NODE_SERVER_PORT}/bookingsheets/patchAttendee`;
      await axios({
        method: "PUT",
        withCredentials: true,
        url: SAVE_ATTENDEE_URL,
        data: {
          // data is put in req.body by Axios
          bookingSheetId: bookingData.bookingSheetId, // where to save
          memberName: attendee.memberName,
          cartId: attendee.cartId, // only used for updateType ADD_PARTNER_CART_ID
          cartNeeds: attendee.cartNeeds,
          potOfGold: attendee.potOfGold,
          updateType: updateType, // 4 possible type of update ( see API for doco
        },
      });
      //console.log("members: ",members.data);
    } catch (error) {
      handleAxiosError(error);
    } finally {
      //console.log(res);
    }
  }; // end of patchAttendeeInBookingSheet()

  /* ************************************************************** */
  // Axios function to add an attendee to Mongo bookingSheet
  // if the attendee is sharing cart with a left member, then
  // state variable "leftCartId" will have a value
  const saveAttendeeToBookingSheet = async (newAttendee) => {
    //console.log(newAttendee);
    try {
      // Pass the search criteria in req.data
      //console.log(`Saving an attendee in MongoDB in bookingSHeet ${bookingData.bookingSheetId}`);
      const SAVE_ATTENDEE_URL = `http://${ARMY_NODE_SERVER_HOST}:${ARMY_NODE_SERVER_PORT}/bookingsheets/addAttendee`;
      await axios({
        method: "POST",
        withCredentials: true,
        url: SAVE_ATTENDEE_URL,
        data: {
          // data is put in req.body by Axios
          bookingSheetId: bookingData.bookingSheetId, // where to save
          visitorGolfLink: newAttendee.visitorGolfLink,
          memberName: newAttendee.memberName,
          cartNeeds: leftCartId ? "shared" : newAttendee.cartNeeds,
          sharingWith: newAttendee.sharingWith,
          bookedBy: newAttendee.bookedBy,
          cartId: leftCartId ? leftCartId : null, //null/shared, API adds a new cartId
          potOfGold: newAttendee.potOfGold,
        },
      });
      //console.log("members: ",members.data);
    } catch (error) {
      handleAxiosError(error);
    }
  }; // end of saveAttendeeToBookingSheet

  // callback to delete a player/attendee
  // The object passed here is like, below with some fields optional
  // {attendee:{memberName:"Warren Howard", cartNeeds: "walk", bookedBy:"name", sharingWith:"name"}}
  function handleDeleteAttendee(attendeeToDelete) {
    //console.log("delete", attendeeToDelete);
    deleteAttendeeFromBookingSheet(attendeeToDelete).then(() => {
      setMessage({ title: "Booking cancelled:", text: attendeeToDelete.memberName });
      //remove from the database record
      setAttendeesChanged(true);
    });
  }

  //  deleteAttendeeFromBookingSheet(attendeeToDelete);
  // Axios function to remove a name from Mongo bookingSheet
  const deleteAttendeeFromBookingSheet = async (attendeeToDelete) => {
    const memberName = attendeeToDelete.memberName;
    let res = {};
    try {
      // Pass the search criteria in req.data
      //console.log(`Deleting attendee from MongoDB in bookingSheet ${bookingData.bookingSheetId}`);
      const DELETE_ATTENDEE_URL = `http://${ARMY_NODE_SERVER_HOST}:${ARMY_NODE_SERVER_PORT}/bookingsheets/deleteAttendee`;
      res = await axios({
        method: "POST",
        withCredentials: true,
        url: DELETE_ATTENDEE_URL,
        data: {
          // data is put in req.body by Axios
          bookingSheetId: bookingData.bookingSheetId,
          attendee: { memberName: memberName }, // assumes names are unique
        },
      });
      //console.log("members: ",members.data);
    } catch (error) {
      handleAxiosError(error);
    } finally {
      //console.log(res);
    }
  }; // end deleteAttendeeFromBookingSheet

  function adminIsLoggedIn() {
    if (loggedIn.role === ADMIN) {
      return true;
    }
    return false;
  }

  // function to check if the loggedIn user is registered
  // NOTE: Code duplicated in <DisplayFixtures>
  function loggedInUserIsRegistered() {
    if (attendees && attendees.length > 0) {
      for (let attendee of attendees) {
        // forEach() cant break out
        if (attendee.memberName === loggedIn.user) {
          return true;
        }
      }
    }
    return false;
  }

  // count the number of people who requested a cart
  // NOTE: Code duplicated in <DisplayFixtures>
  function cartsCount() {
    let count = 0;
    attendees.forEach((attendee) => {
      if (attendee.cartNeeds === "shared") count += 0.5;
      else if (attendee.cartNeeds === "unshared") count++;
    });
    return count;
  }

  // count the number of people registered by the logged in user
  // any logged in user except admin is allowed only 2 bookings including himself
  function bookingsCount() {
    let count = 0;
    attendees.forEach((attendee) => {
      if (attendee.bookedBy === loggedIn.user) count += 1;
    });
    return count;
  }

  // This prints the list of cart buddies (admin only)
  function PrintCartBuddyList() {
    //console.log("<PrintCartBuddy>");
    // This maps through the rows
    // If a row starts with a walker then ignore it
    // if a row has 1 cart user then print it
    // if a row has 2 entries print them both getLoggedInMember
    return (
      <>
        {attendeeRows.map((row) => {
          return (
            <Box key={uuidv4()}>
              {row.length === 1 && row[0].cartNeeds === "walk" ? null : (
                <Box display="flex" flexDirection="row">
                  <Box width="10%"></Box>
                  {row.length === 1 ? (
                    row[0].cartNeeds !== "walk" ? (
                      <>
                        <Box width="35%">
                          <span>
                            <Icon
                              path={mdiGolfCart}
                              style={{ height: "18px", width: "22px", paddingRight: "10px" }}
                            />
                          </span>
                          <span>{row[0].memberName}</span>
                        </Box>
                        <Box width="20%">
                          {row[0].cartNeeds === "unshared" ? "Requests cart alone" : null}
                        </Box>
                        <Box width="35%"></Box>
                      </>
                    ) : null
                  ) : null}
                  {row.length === 2 ? (
                    <>
                      <Box width="35%">
                        <span>
                          <Icon
                            path={mdiGolfCart}
                            style={{ height: "18px", width: "22px", paddingRight: "10px" }}
                          />
                        </span>
                        <span>{row[0].memberName}</span>
                      </Box>
                      <Box width="20%">sharing with</Box>
                      <Box width="35%">
                        <span>
                          <Icon
                            path={mdiGolfCart}
                            style={{ height: "18px", width: "22px", paddingRight: "10px" }}
                          />
                        </span>
                        <span>{row[1].memberName}</span>
                      </Box>
                    </>
                  ) : null}
                </Box>
              )}
            </Box>
          ); // end of return
        })}
      </>
    );
  }

  // if we reach here and not logged in then
  // redirect to the public home page
  if (loggedIn.status === false) {
    return <Navigate to="/" />;
  } else {
    return (
      <>
        {!fixtureIsLoaded ? (
          "Loading booking sheet...."
        ) : (
          <>
            <Link // Link to navigate back to Fixtures
              to="/AboutGolf/Fixtures"
            >
              <ArrowBackIcon className={classes.backIcon} />
              <span className={classes.backButton}> Back to Fixtures</span>
            </Link>

            {/** ******************* Dialogs that pop up on request *******************
            Note. Dialogs are children of DOM <body> and when activated are 
              placed at the center of the screen */}
            <Dialog // selects a member and cart/potOfGoldOptions and saves to state
              onClose={() => {
                setOpenDialog(false);
              }}
              open={openDialog} // this state is initially false (dialog closed)
              aria-labelledby="select-member"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                className={classes.dialogBox}
              >
                <Box className={classes.selectMemberBox}>
                  <SelectMember
                    currentAttendees={attendees}
                    // if user selects a member then this callback saves it to state
                    saveSelectedMember={saveSelectedMemberCallback}
                  ></SelectMember>
                </Box>
                <SelectCart // attendee is member selected, or loggedIn member
                  selectedMember={
                    selectedMember.memberName === "" && // eg before selecting a member
                    !adminIsLoggedIn() &&
                    !loggedInUserIsRegistered()
                      ? { memberName: loggedIn.user, cartNeeds: "walk", potOfGold: false } // use the loggedIn user
                      : selectedMember // user the member selected by user.
                  }
                  saveSelections={saveNewAttendeeCallback} // function to save the cart needs and more
                  cancelSelection={cancelMemberSelection}
                  cart={leftCartId ? "shared" : null}
                ></SelectCart>
              </Box>
            </Dialog>
            {/**  ************************ END Dialog **********************    */}

            <Dialog // Admin only: Select booked member to fill a cart
              onClose={() => {
                setOpenCartDialog(false);
              }}
              open={openCartDialog} // this state is initially false (dialog closed)
              aria-labelledby="select-member-to-share-cart"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                className={classes.cartDialogBox}
              >
                <Box className={classes.selectMemberBox}>
                  <Autocomplete
                    classes={{ option: classes.options }}
                    id="combo-box"
                    options={unFullCarts}
                    getOptionLabel={(option) => option.memberName}
                    getOptionDisabled={(option) => {
                      if (
                        option.memberName === leftAttendee.memberName // we dont want this in the option list
                      ) {
                        return true; // option is disabled if test above is true
                      } else {
                        return false;
                      }
                    }}
                    style={{ width: 250 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select member to share cart"
                        variant="outlined"
                      />
                    )}
                    size="small"
                    // onChange signature: function(event: object, value: any)
                    onChange={(event, memberName) => memberMovedToCart(event, memberName)}
                  />
                </Box>
              </Box>
            </Dialog>
            {/**  ************************ END FillCart Dialog **********************    */}

            {/**
                allows to change cart option (only) when user clicks cart/walk icon
                we need to know the memberName and current option of the cliked line
             */}
            <Dialog //
              onClose={() => {
                setOpenCartOptionDialog(false);
              }}
              open={openCartOptionDialog} // this state is initially false (dialog closed)
              aria-labelledby="select-member"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                className={classes.dialogBox}
              >
                <SelectCart // attendee is member whose walk/cart icon is selected
                  selectedMember={selectedMember} // this should be the state variable
                  saveSelections={saveSelectionsCallback} // function to save the cart needs
                  cancelSelection={cancelOptionSelectionCallback}
                  cart={selectedMember.cartNeeds}
                  pog={selectedMember.potOfGold}
                ></SelectCart>
              </Box>
            </Dialog>
            {/**  ************************ END edit cartNeeds Dialog **********************    */}

            <Box // this box is the whole page with a solid black border
              display="flex"
              flexDirection="column"
              alignItems="center"
              className={classes.centeringContainer}
            >
              {/* title box goes here */}
              <TitleBox
                bookingData={bookingData}
                attendeeCount={attendees.length}
                cartCount={cartsCount()}
              />

              <Box // this box holds and centres the  message area
                sx={{ border: "1px dashed grey", padding: "5px" }} // temporary border
              >
                <span style={{ fontWeight: "bold", marginRight: "10px" }}>{message.title}</span>
                <span>{message.text}</span>
              </Box>

              <Box // this box positions the attendee list and headings in the center
                display="flex"
                flexDirection="column"
                alignItems="center"
                className={classes.attendeeListContainer}
                sx={{ border: "1px dashed grey" }} // temporary border
              >
                <Typography style={{ marginBottom: "5px" }} variant="h6">
                  {`Registrations for ${bookingData.course}`}
                </Typography>
                <Box //this box holds the logged In field and 3 buttons for Admin
                  className={classes.controlBox}
                >
                  <Box display="flex" className={classes.controlPane}>
                    <Box>
                      <Typography style={{ marginBottom: "1px" }} variant="body1">
                        <span style={{ fontWeight: "bold" }}>LoggedIn: </span>
                        <span style={{ color: "red" }}>{loggedIn.user}</span>
                      </Typography>
                    </Box>
                    {/* PrintRegistered code usd to go here - see below*/}
                  </Box>
                  <Box display="flex" className={classes.controlPane}>
                    {loggedIn.role === ADMIN ? ( // only needed for admin user
                      <Box>
                        <div>
                          <ReactToPrint
                            trigger={() => (
                              <button className={classes.printButton}>Print Cart Buddies</button>
                            )}
                            content={() => cartBuddyListRef.current}
                          />
                          <div style={{ display: "none" }}>
                            {/** this box is for the printed component */}
                            <Box ref={cartBuddyListRef}>
                              <Box height="20px"></Box>
                              <TitleBox
                                bookingData={bookingData}
                                attendeeCount={attendees.length}
                                cartCount={cartsCount()}
                              />
                              <Typography
                                variant="h6"
                                style={{
                                  textAlign: "center",
                                  paddingTop: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                Captains Pre-Match printout (carts)
                              </Typography>
                              <PrintCartBuddyList />
                            </Box>
                          </div>
                        </div>
                      </Box>
                    ) : null}
                    {loggedIn.role === ADMIN ? ( // Button to make flights
                      <Box>
                        <Tooltip
                          disableFocusListener
                          disableTouchListener
                          arrow
                          title={
                            <span style={{ fontSize: "14px" }}>Click to make the timesheet</span>
                          }
                        >
                          <Box
                            component={Link}
                            to={"/makeTimesheet/" + fixtureId}
                            className={classes.printButton}
                          >
                            <button className={classes.printButton}>Timesheet</button>
                          </Box>
                        </Tooltip>
                      </Box>
                    ) : null}
                  </Box>
                </Box>

                <DisplayAttendeeList // attendee display table
                  attendeeRows={attendeeRows}
                  unFullCarts={unFullCarts}
                  sortAttendees={sortAttendees} // sort the attendees for display purposes
                  handleDeleteAttendee={handleDeleteAttendee}
                  handleOpenDialog={handleOpenDialog}
                  handleOpenCartDialog={handleOpenCartDialog}
                  handleOpenCartOptionDialog={handleOpenCartOptionDialog}
                />
              </Box>
            </Box>

            <div className={classes.backJustify}>
              <Link to="/AboutGolf/Fixtures">
                <ArrowBackIcon className={classes.backIcon} />
                <span className={classes.backButton}> Back to Fixtures</span>
              </Link>
            </div>
          </>
        )}
      </>
    );
  }
}

export default BookingSheet;

// Old code for printRegistered List
// {loggedIn.role === ADMIN ? ( // only needed for admin user
//   <Box>
//     <ReactToPrint
//       trigger={() => (
//         <button className={classes.printButton}>Print Registered</button>
//       )}
//       content={() => membersListRef.current}
//       //pageStyle format found in source of react-to-print
//       pageStyle="@page { size: auto;  margin: 10mm; } @media print { body { -webkit-print-color-adjust: exact; } }"
//     />
//     <div style={{ display: "none" }}>
//       {/** this box is for the printed component */}
//       <Box ref={membersListRef}>
//         <Box height="20px"></Box>
//         <TitleBox
//           bookingData={bookingData}
//           attendeeCount={attendees.length}
//           cartCount={cartsCount()}
//         />
//         <Typography
//           variant="h6"
//           style={{
//             textAlign: "center",
//             paddingTop: "10px",
//             paddingBottom: "10px",
//           }}
//         >
//           Captains Pre-Match printout (players)
//         </Typography>
//         <PrintRegisteredList />
//       </Box>
//     </div>
//   </Box>
// ) : null}

// This prints out the full members list with the registered members ticked
// as well as their PoG and paid status
// function PrintRegisteredList() {
//   //console.log("<PrintRegisteredList>");
//   // useEffect is a fn that we pass a function to execute as well as an array of dependencies
//   // Read the fixture with polulated BookingSheet
//   const [membersList, setMembersList] = useState([]);
//   useEffect(() => {
//     //console.log("fetching members list");
//     let mounted = true;
//     // First define fetchData() function to read the full membersList
//     const fetchData = async () => {
//       try {
//         // get the fixture from MongoDB WITH bookingSheet populated
//         //console.log(`Getting fixture ${fixtureId}  from MongoDB`);
//         const GET_MEMBERS_URL = `http://${ARMY_NODE_SERVER_HOST}:${ARMY_NODE_SERVER_PORT}/members2/listNames`;
//         const response = await axios({
//           method: "GET",
//           withCredentials: true,
//           url: GET_MEMBERS_URL,
//         });
//         // This API returns a userArray
//         const { userArray } = response.data; //destructuring
//         // save the array to state
//         if (mounted) {
//           //fill in "paid" and "POG" status on memberList
//           // first make an association array of the attendees
//           let assocAttendees = [];
//           attendees.forEach((attendee) => {
//             assocAttendees[attendee.memberName] = attendee;
//           });
//           // now run through the list of members and add the attendance and PoG
//           userArray.forEach((member) => {
//             let memberName = `${member.firstName} ${member.lastName}`;
//             if (assocAttendees[memberName]) {
//               member.paid = true;
//               member.potOfGold = assocAttendees[memberName].potOfGold;
//             }
//           });
//           //console.log(userArray);
//           setMembersList(userArray); // save to state
//         }
//       } catch (error) {
//         handleAxiosError(error);
//       }
//     }; // end of fetchdata() definition

//     // here we fetch the data
//     fetchData();
//     return () => (mounted = false); // cleanup
//   }, []);

//   // This is printed at the start of every page
//   function PrintTitleRow() {
//     return (
//       <>
//         <Box display="flex" fontWeight="bold">
//           <Box width="25%"></Box>
//           <Box width="10%">Playing</Box>
//           <Box width="15%" textAlign="center">
//             Grade
//           </Box>
//           <Box width="12%"></Box>
//           <Box width="12%"></Box>
//           <Box width="20%"></Box>
//           <Box width="7%">GA</Box>
//         </Box>
//         <Box display="flex" fontWeight="bold" borderBottom={1}>
//           <Box width="25%" textAlign="center">
//             Player
//           </Box>
//           <Box width="10%">H/Cap</Box>
//           <Box width="5%">A</Box>
//           <Box width="5%">B</Box>
//           <Box width="5%">C</Box>
//           <Box width="12%" textAlign="center">
//             Paid
//           </Box>
//           <Box width="12%" textAlign="center">
//             POG
//           </Box>
//           <Box width="20%" textAlign="center">
//             GolfLink
//           </Box>
//           <Box width="7%">H/Cap</Box>
//         </Box>
//       </>
//     );
//   }

//   return (
//     <>
//       <PrintTitleRow />
//       <Box borderTop="1px solid black" borderBottom="1px solid black">
//         {membersList.map((member, index) => {
//           return (
//             <Box key={uuidv4()}>
//               <Box display="flex" bgcolor={index % 2 === 0 ? "#d6f1d7" : null}>
//                 <Box width="25%">
//                   {member.lastName}, {member.firstName}
//                 </Box>
//                 <Box width="10%"></Box>
//                 <Box width="5%"></Box>
//                 <Box width="5%"></Box>
//                 <Box width="5%"></Box>
//                 <Box width="12%" borderRight={1} borderLeft={1} textAlign="center">
//                   {member.paid && (
//                     <Icon
//                       path={mdiCheck}
//                       style={{ height: "18px", width: "22px", fontWeight: "bold" }}
//                     />
//                   )}
//                 </Box>
//                 <Box width="12%" borderRight={1} textAlign="center">
//                   {member.potOfGold && (
//                     <Icon path={mdiCheck} style={{ height: "18px", width: "22px" }} />
//                   )}
//                 </Box>
//                 <Box width="20%" textAlign="center">
//                   {member.golfLink}
//                 </Box>
//                 <Box width="7%"></Box>
//               </Box>
//               {index === 48 ? (
//                 <>
//                   <p style={{ pageBreakAfter: "always" }}></p>
//                   <Box height="20px"></Box>
//                   <PrintTitleRow />
//                 </>
//               ) : null}
//             </Box>
//           );
//         })}
//       </Box>
//     </>
//   );
// } // // end PrintRegisteredList()
