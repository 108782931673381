export const menuMembers = [
  { component: "Home" },
  {
    submenu: "About Golf",
    submenuItems: [
      { component: "Fixtures - Booking" },
      { component: "Club Championships" },
      { component: "Order Of Merit" },
      { component: "Eclectic" },
      { component: "Bent Putter" },
      { component: "Monthly Medals" },
    ],
  },
  {
    submenu: "Members",
    submenuItems: [
      { component: "Notices" },
      { component: "Members Details" },
      { component: "Code Of Conduct" },
      { component: "Mugs Gallery" },
    ],
  },
  {
    submenu: "Governance",
    submenuItems: [
      { component: "Constitution" },
      { component: "By Laws" },
      { component: "Minutes" },
      { component: "Reports" },
    ],
  },
  {
    submenu: "Rules",
    submenuItems: [
      { component: "Rules Explained" },
      { component: "Championship Rules" },
      { component: "Match Play Rules" },
      { component: "Medal Of Medals Rules" },
      { component: "Order Of Merit Rules" },
      { component: "Eclectic Rules" },
    ],
  },
  {
    submenu: "Honours Board",
    submenuItems: [
      { component: "Office Bearers And Champions" },
      { component: "Annual Awards" },
      { component: "Annual Trophies" },
      { component: "Other Boards" },
    ],
  },
  { component: "Contact Us" },
  { component: "Logout" },
];

export const menuPublic = [
  { component: "Home" },
  {
    submenu: "About Our Club",
    submenuItems: [{ component: "About Us" }, { component: "In Memorium" }],
  },
  { component: "Objectives" },
  { component: "Contact Us" },
  { component: "Membership Application" },
  { component: "Members Login" },
];
