import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  regBox: {
    minWidth: "280px",
    marginTop: "15px",
    border: "1px solid grey",
  },
  buttonBox: {
    paddingTop: "5px",
    paddingBottom: "10px",
  },
  memberName: {
    paddingTop: "5px",
    paddingBottom: "10px",
    textAlign: "center",
  },
  nameHeader: {
    fontWeight: "bold",
    paddingRight: "5px",
  },
}));

// This component is for the Pot Of Gold Checkbox
// See materialUI doco for other examples
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

/**
 * <SelectCart> presents radio buttons with cart options
 * and either a "Register" or "Save" button.
 * "Save" is used to change the cart needs of a registered member
 * "Register" is used to register a new atendee and save cart needs
 * <SelectCard attendee handleRegisterMember|handleSaveCartChanges>
 */
function SelectCart({ selectedMember, saveSelections, cancelSelection, cart, pog }) {
  /**
   * selectedMember         : the member object to select cartNeeds
   * saveSelectedOptions()  : The callback to use when "confirm" is pressed
   * cancelSelection()      : The callback to use when the operation is aborted
   * cart                   : The string "walk"|"shared"|"unshared" to use as initial value
   * pog                    : true:false for potOfGold
   *
   * called from <DisplayAttendeeList> via one of the <Dialog>s
   */

  // Note:
  // selectedMember is passed in here but it could be the state variable [selectedMember]
  // and we dont want to change it from
  // So instead we make a clone

  let clonedSelectedMember = { ...selectedMember };
  console.log("clonedSelectedMember", clonedSelectedMember);

  const classes = useStyles();

  // if a value is passed in then use it, otherwise take the default
  const [cartNeeds, setCartNeeds] = useState(cart ? cart : "walk");
  const [potOfGold, setPotOfGold] = useState(pog ? pog : false);

  // callbacks to save selection - (before Register Button is pressed).
  const handleChangeCartNeeds = (event) => {
    setCartNeeds(event.target.value);
  };

  const handleChangePotOfGold = (event) => {
    //console.log("clicked potOfGold to: ", event.target.checked);
    setPotOfGold(event.target.checked);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" className={classes.regBox}>
      <div className={classes.memberName}>
        <span className={classes.nameHeader}>Player:</span>
        <span style={{ color: "red" }}>
          {selectedMember.memberName ? selectedMember.memberName : "Select or enter player"}
        </span>
      </div>
      <FormControlLabel
        control={
          <GreenCheckbox checked={potOfGold} onChange={handleChangePotOfGold} name="checkedG" />
        }
        label="Tick to enter Pot of Gold"
      />
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Select cart needs"
          name="cart select"
          value={cartNeeds} // this is the default value of the radio group
          onChange={handleChangeCartNeeds}
          row
        >
          <FormControlLabel value="walk" control={<Radio size="small" />} label="Walk" />
          <FormControlLabel value="shared" control={<Radio size="small" />} label="Cart" />
          <FormControlLabel value="unshared" control={<Radio size="small" />} label="Cart solo" />
        </RadioGroup>
      </FormControl>
      <Box display="flex" justifyContent="space-around" width="100%" className={classes.buttonBox}>
        <Tooltip
          disableFocusListener
          disableTouchListener
          placement="bottom"
          title={
            selectedMember.memberName
              ? "Click to confirm booking"
              : "Disabled until member selected"
          }
        >
          <span>
            {/** <span> needed becasue of tooltip - gives errors otherwise */}
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                clonedSelectedMember.cartNeeds = cartNeeds;
                clonedSelectedMember.potOfGold = potOfGold;
                saveSelections(clonedSelectedMember); // save attendee and do some cleanup
              }}
              disabled={selectedMember.memberName === ""}
            >
              Confirm
            </Button>
          </span>
        </Tooltip>
        <Tooltip
          disableFocusListener
          disableTouchListener
          placement="bottom"
          title="Click to cancel booking"
        >
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              cancelSelection(); // this cancels selection process
            }}
          >
            Cancel
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
}

export default SelectCart;
