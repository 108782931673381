import React, { useContext } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import LoggedInContext from "../components/loggedInContext.js";
import NavController from "./navController";

// makestyles automatically passes the current theme as parameter
const useStyles = makeStyles((theme) => ({
  headerContainer: {
    position: "fixed",
    top: "0",
    left: "0",
    backgroundColor: "green",
    zIndex: "1",
  },
  logosAll: {
    maxWidth: "700px",
  },
  logoContainer1: {
    flex: "1 1 0",
    marginTop: "3px",
    marginBottom: "3px",
    border: "2px solid black",
  },
  logoContainer2: {
    flex: "3 1 0",
    marginTop: "3px",
    marginBottom: "3px",
    border: "2px solid black",
  },
  logo1: {
    width: "100%",
    height: "100%",
  },
  logo3: {
    width: "100%",
    height: "100%",
  },
}));

function Header() {
  const classes = useStyles();
  const { loggedIn } = useContext(LoggedInContext);

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  return (
    <Container maxWidth={false} className={classes.headerContainer}>
      <Box display="flex" justifyContent="center">
        <Box
          className={classes.logosAll}
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <LightTooltip
            disableFocusListener
            disableTouchListener
            title="Open Facebook in a new tab"
            placement="top"
          >
            <Box className={classes.logoContainer1}>
              <a
                href="https://www.facebook.com/groups/999118716828949/"
                rel="noreferrer"
                target="_blank"
              >
                <img className={classes.logo3} src={`/facebookLogo.png`} alt="Facebook logo" />
              </a>
            </Box>
          </LightTooltip>
          <LightTooltip
            disableFocusListener
            disableTouchListener
            title="Open handicap lookup in a new tab"
            placement="top"
          >
            <Box className={classes.logoContainer1}>
              <a
                href={
                  loggedIn.status
                    ? `https://www.golf.org.au/handicap/?golfLinkNo=${loggedIn.golfLink}`
                    : "https://www.golf.org.au/"
                }
                rel="noreferrer"
                target="_blank"
              >
                <img
                  className={classes.logo1}
                  src={`/handicapLookupLogo.png`}
                  alt="Handicap lookup logo"
                />
              </a>
            </Box>
          </LightTooltip>
          <LightTooltip
            disableFocusListener
            disableTouchListener
            title="Army Golf WA logo"
            placement="top"
          >
            <Box className={classes.logoContainer1}>
              <img className={classes.logo1} src={`/armyGolfLogoSquare.png`} alt="Army golf logo" />
            </Box>
          </LightTooltip>
          <LightTooltip
            disableFocusListener
            disableTouchListener
            title="Army Golf WA banner"
            placement="top"
          >
            <Box className={classes.logoContainer2}>
              <img className={classes.logo1} src={`/armyGolfLogo.png`} alt="Army golf banner" />
            </Box>
          </LightTooltip>
          <LightTooltip
            disableFocusListener
            disableTouchListener
            title="Open golfWA site in a new tab"
            placement="top"
          >
            <Box className={classes.logoContainer1}>
              <a href="https://www.golfwa.org.au/" rel="noreferrer" target="_blank">
                <img className={classes.logo1} src={`/golfwaLogo.png`} alt="Golf WA logo" />
              </a>
            </Box>
          </LightTooltip>
          <LightTooltip
            disableFocusListener
            disableTouchListener
            title="Open Golf Australia site in a new tab"
            placement="top"
          >
            <Box className={classes.logoContainer1}>
              <a href="https://www.golf.org.au/" rel="noreferrer" target="_blank">
                <img
                  className={classes.logo1}
                  src={`/golfAustraliaLogo.png`}
                  alt="Golf Australia logo"
                />
              </a>
            </Box>
          </LightTooltip>
          <LightTooltip
            disableFocusListener
            disableTouchListener
            title="Open Royal and Ancient site in a new tab"
            placement="top"
          >
            <Box className={classes.logoContainer1}>
              <a href="https://www.randa.org/" rel="noreferrer" target="_blank">
                <img
                  className={classes.logo1}
                  src={`/RandALogo.png`}
                  alt="Royal and Ancient logo"
                />
              </a>
            </Box>
          </LightTooltip>
        </Box>
      </Box>
      <NavController />
    </Container>
  );
}

export default Header;
/*
   
*/
