import React, { useState, useEffect } from "react";
import axios from "axios";
import { API } from "aws-amplify";
import { makeStyles } from "@material-ui/styles";
import { Document, Page, pdfjs } from "react-pdf";
import { getWithExpiry, setWithExpiry } from "../utils/localStorage.js";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
//import ADDRESSES from "../IPandPorts.js";
import ADDRESSES, { BODY } from "../IPandPorts.js";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const URL = `${ADDRESSES.PDFIP}`; // Brendans PC is 10.0.0.5 where web server runs
const PORT = ADDRESSES.WebPort; // Web server is on PORT 3000

// NOTE: We may be reading pdfs from the armygolclubwa site, and some of these require
// basic http authorisation. This is achieved by adding the header
// Authorization: Basic Z29sZmE6YmVudHB1dHRlcg==    which is the BASE64 encoding of golfa:bentputter
// Generate it here https://www.blitter.se/utils/basic-authentication-header-generator/
// If you do this the browser "may" provide an alert form to enter the authorisation
// There are 2 options to add this header
// 1. by adding the header as a url option in the <Document> component below
//    described here https://github.com/wojtekmaj/react-pdf
//    like Parameter object:
//    { url: 'http://example.com/sample.pdf',
//      httpHeaders: { 'Authorization': 'Basic Z29sZmE6YmVudHB1dHRlcg==' } }
// 2. by using     proxy_set_header Authorization 'Basic Z29sZmE6YmVudHB1dHRlcg==';  in nginx
// Currently trying option 2
//---------------------------------------------------------------------------

// makestyles automatically passes the current theme as parameter
const useStyles = makeStyles((theme) => ({
  documentBorder: {
    borderStyle: "solid",
    width: "100%",
  },
}));

export default function DisplayPDF({ fileName }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [token, setToken] = useState(""); // the MSGraph token for the registered Azure App
  const [url, setUrl] = useState(""); // the signed url of the target file
  const [apiError, setApiError] = useState(false);

  console.log("in DisplayPDF displaying ", fileName);

  let classes = useStyles();

  // this use effect calls the getOneDriveToken lambda via the APi apiGetOneDriveToken /oneDriveToken
  useEffect(() => {
    async function getToken() {
      let token = getWithExpiry("token");
      if (token) {
        setToken(token);
      } else {
        const response = await API.get("apiGetOneDriveToken", "/oneDriveToken");
        console.log("response: ", response);
        setToken(response.access_token);
        setWithExpiry("token", response.access_token, 60 * 58 * 1000); // 58 mins in ms
      }
    }
    getToken();
  }); // need it to run ever time becasue the token can expire in localStorage

  useEffect(() => {
    async function getFileUrl() {
      const FILE_URL = `https://graph.microsoft.com/v1.0/drives/b!R4T0Vmiqb02DBArVNy0jBEg-mU0lOudPh_QV0PmucwVIYgffNZ_lR4uHD2E51qNV/root:/httpdocs/${fileName}:/`;
      console.log("displayPDF url", FILE_URL);
      let res = {};
      try {
        res = await axios({
          method: "GET",
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
          url: FILE_URL,
        });
        console.log("url", res["data"]["@microsoft.graph.downloadUrl"]);
        setUrl(res["data"]["@microsoft.graph.downloadUrl"]);
        setApiError(false);
      } catch (error) {
        console.log("error", error);
        setApiError(true);
      }
    } // end getFileUrl()

    // actually fetch the data
    console.log("fetching file URL");
    if (token !== "") {
      getFileUrl();
    }
  }, [token, fileName]); // executes if the token or filename changes

  const getDivWidth = () => {
    // Note paperBase is defined in App.js
    if (document.getElementById("paperBase")) {
      return document.getElementById("paperBase").clientWidth - 70;
    } else {
      console.log("using approximation");
      return window.innerWidth < 1000 ? window.innerWidth * 0.75 : 900; // rough guess
    }
  };

  // This custom hook returns the width of the canvasContainer when it resizes
  function useCurrentDivWidth() {
    const [divWidth, setDivWidth] = useState(getDivWidth());

    useEffect(() => {
      // timeoutId for debounce mechanism
      let timeoutId = null;
      const resizeListener = () => {
        // prevent execution of previous setTimeout
        clearTimeout(timeoutId);
        // change width from the state object after 150 milliseconds
        timeoutId = setTimeout(() => setDivWidth(getDivWidth()), 150);
      };

      //set resize listener
      window.addEventListener("resize", resizeListener);

      // clean up (executed before the component dismounts)
      return () => {
        window.removeEventListener("resize", resizeListener);
      };
    }, []);
    return divWidth;
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function goToPrevPage() {
    if (pageNumber > 1)
      setPageNumber((pageNumber) => setPageNumber(pageNumber - 1));
  }

  function goToNextPage() {
    if (pageNumber < numPages)
      setPageNumber((pageNumber) => setPageNumber(pageNumber + 1));
  }

  let divSize = useCurrentDivWidth();

  return url !== "" ? (
    <div id="canvasContainer">
      <Document
        className={classes.documentBorder}
        //file={`${URL}:${PORT}/${fileName}`}
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        error="..failed to load PDF"
      >
        <Page width={divSize} pageNumber={pageNumber} />
      </Document>

      <div style={{ width: "200px", textJustify: "right", display: "flex" }}>
        Page {pageNumber} of {numPages}
        <nav style={{ justifyContents: "space-between" }}>
          {/* Display control is more than 1 page */}
          {numPages > 1 ? (
            <React.Fragment>
              <button onClick={goToPrevPage}>Prev</button>
              <button onClick={goToNextPage}>Next</button>
            </React.Fragment>
          ) : null}
        </nav>
      </div>
    </div>
  ) : apiError !== "" ? (
    <div>"Requesting document URL on OneDrive..."</div>
  ) : (
    <div>"Error reading document URL from OneDrive"</div>
  );
}
