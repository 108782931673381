import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import LoggedInContext from "../components/loggedInContext.js";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import ADDRESSES from "../IPandPorts.js";
import { handleAxiosError } from "../utils/handleAxiosError.js";
import Box from "@material-ui/core/Box";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { makeStyles } from "@material-ui/styles";
import { deleteFromLocalStorage } from "../utils/localStorage.js";

// NOTE: we cant use getenv with React - although we could load variable from a file
const ARMY_NODE_SERVER_HOST = ADDRESSES.IP;
const ARMY_NODE_SERVER_PORT = ADDRESSES.APIPort;

// makestyles automatically passes the current theme as parameter
const useStyles = makeStyles((theme) => ({
  button: {
    color: "white",
  },
  username: {
    color: "white",
    //fontSize: "10px",
    paddingRight: "2px",
  },
  menuItem: {
    fontSize: "14px",
    color: "white",
    paddingTop: "5px",
    paddingLeft: "5px",
    flex: "1 1 0", //makes them all the same width
    [theme.breakpoints.up("md")]: {
      flex: "1 1 auto",
      fontSize: "16px",
    },
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "red",
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
}));
/**
 * About login/logout
 * When a user logs in (by sending /login with name/password):
 *   When authenticated the server sends a cookie to the browser in its response
 *   The browser sends the cookie with every request after that
 *   The server examines the cookie and sets req.user to the username logged in
 *   This way the server "knows" if anyone is logged in and who.
 *
 *   The client side can read the /login response and see if login was good.
 *   If good it:
 *      Sets the loggedIn context variable to {status:true, username: "username"}
 *
 *   After login we redirect to "/"
 *   App.js will create routes appropriate to the logged in state and navbar.js will
 *   create respective menu options.
 *   navbar also adds this <Logout> component which is not linked like other menu items
 *   instead it has a buttun, which when pressed:
 *      Activates the /logout api to tell the server to remove its cookie
 *      Sets the loggedIn context variable to false
 *   When <Logout> is activated after loggedIn = false it redirects to "/"
 *   the public home page.
 *
 */

// Note: The logout component is different in that it is not managed by React as a
// route. It simply presents a logout button in the navbar. When pressed
// the logout api is called.

function Logout() {
  // make css classes in JSS
  const classes = useStyles();

  // loggedIn looks like {status: true|false, user: name}
  const { loggedIn, setLoggedIn } = useContext(LoggedInContext);

  // This is how we navigate with Router V6
  let navigate = useNavigate();

  // process the login when user clicks submit buttom
  async function logoutOld() {
    // we are logging out so kill the localStorage variable
    //deleteFromLocalStorage("userName");

    // now tell the server we are logging out
    const LOGOUT_URL = `http://${ARMY_NODE_SERVER_HOST}:${ARMY_NODE_SERVER_PORT}/logout`;
    let res = {};
    try {
      res = await axios({
        method: "POST",
        withCredentials: true,
        url: LOGOUT_URL,
      });
      console.log(res.data);
      if (res.data.username) {
        // username will be set if logged out
        console.log(`${res.data.username} logged out`);
      } else {
        console.log("no one to logout - server cookie already expired");
      }
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setLoggedIn({ status: false });
      navigate("/"); // This is how we navigate with Router V6
    }
  } // end of logout() definition

  // bent putter version
  async function logout() {
    // we are logging out so kill the localStorage variable
    deleteFromLocalStorage("loggedIn");
    setLoggedIn({ status: false });
    navigate("/"); // This is how we navigate with Router V6
  } // end of logout() definition

  console.log("logged in:", loggedIn);
  // This is presented if we are logged in
  return (
    <Tooltip disableFocusListener disableTouchListener title="Logout">
      <Box className={classes.menuItem} onClick={logout}>
        <span className={classes.button}>
          <ExitToAppIcon />
        </span>
        <span className={classes.username}> {loggedIn.user}</span>
      </Box>
    </Tooltip>
  );
}
export default Logout;
