/**
 * Component to enable an admin user (club captain) to construct a timesheet
 * from the registered players list.
 * He will have the registerd list to the left and a timesheet on the right
 * The time sheet rows can be selected one at a time
 * With a timesheet row selected, the captain then selects up to 4 registered player
 * who will then be transferred to the schedule.
 * We are using the :flightTime" attribute of bookingSheet to store the flight and posn as "f:p"
 *
 */
import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { handleAxiosError } from "../utils/handleAxiosError.js";
import LoggedInContext from "../components/loggedInContext.js";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ReactToPrint from "react-to-print";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TitleBox from "./titleBox.js";
import Tooltip from "@material-ui/core/Tooltip";
// Icon and components from "materialdesignicons.com" (@mdi/)
import { Icon } from "@mdi/react";
import { mdiGolfCart } from "@mdi/js"; // to indicate golf cart needed
import { mdiWalk } from "@mdi/js"; // to indicate walking
import { v4 as uuidv4 } from "uuid";
import ADDRESSES from "../IPandPorts.js";
import _ from "lodash";

// These are used to define the type of ID for bookingSheet Updates
// all but CHANGE_FLIGHT_TIME are used by <BookingSheet>
//const ADD_PARTNER_CART_ID = 1; // all hardcoded for now (server-side also)
//const CHANGE_TO_SHARED = 2;
//const CHANGE_FROM_SHARED = 3;
//const CART_NEEDS_UNCHANGED = 4;
const CHANGE_FLIGHT_TIME = 5; // used in <MakeTimeSheet>
const BAD_FLIGHT = 999; // a flightNo that cant exist

const useStyles = makeStyles((theme) => ({
  row: {
    border: "1px solid black",
    textAlign: "left",
    paddingTop: "3px",
    paddingLeft: "3px",
    height: "25px",
  },
  flightNo: {
    textAlign: "center",
    border: "1px solid black",
    paddingTop: "3px",
  },
  printButton: {
    border: "none",
    textAlign: "center",
    fontSize: "16px",
    cursor: "pointer",
    width: "150px",
  },
  backButton: {
    display: "inline-block",
    textDecoration: "none",
    color: "black",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  backIcon: {
    verticalAlign: "-6px",
  },
  golfIcon: {
    paddingTop: "2px",
    height: "16px",
    width: "22px",
  },
  backJustify: {
    display: "flex",
    justifyContent: "flex-end",
  },
  flight: {
    border: "1px solid black",
    textAlign: "left",
    paddingLeft: "3px",
    backgroundColor: "#aea7a7", // grey
    fontWeight: 200,
  },
  noFlight: {
    border: "1px solid black",
    textAlign: "left",
    paddingLeft: "3px",
    "&:hover": {
      cursor: "pointer",
    },
    backgroundColor: "white",
    fontWeight: 400,
  },
  quadSelected: {
    backgroundColor: "#8ee7a4", // light green
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  quadUnSelected: {
    //border: "1px solid black",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
    },
  },
}));

// NOTE: we cant use getenv with React - so loading from IPandPorts.js file
const ARMY_NODE_SERVER_HOST = ADDRESSES.IP;
const ARMY_NODE_SERVER_PORT = ADDRESSES.APIPort;

function MakeTimesheet() {
  const { fixtureId } = useParams(); //as appearing at the end of the URL

  const classes = useStyles();
  // loggedIn looks like {status: true|false, user: name}
  const { loggedIn } = useContext(LoggedInContext);
  const [fixtureIsLoaded, setFixtureIsLoaded] = useState(false); // Loading indicator
  const [fixtureReady, setFixtureReady] = useState(false); // loading indicator
  // This stores data about the fixture including booking sheet id
  const [bookingData, setBookingData] = useState({
    fixtureName: null,
    fixtureDate: null,
    bookingSheetId: null,
  });
  const [forceReload, setForceReload] = useState(false); // force a reload after a save
  // We will have 2 formatted lists
  // First is a list of name pairs, where the right name is only polulated
  // if a cart is being shared
  // The list is ordered so that cart shares are first, then unshared carts, then walkers
  const [rawPlayerList, setRawPlayerList] = useState([]); // The raw list from the DB
  const [playerPairs, setPlayerPairs] = useState([]); // The list for display on left
  const [flightCount, setFlightCount] = useState(0); // calculated from no of players

  // Second is a list of name quadruples, where each row is a flight of up to 4 players
  const [flights, setFlights] = useState([]);
  const [selectedFlight, setSelectedFlight] = useState(BAD_FLIGHT); // start with flight 0

  // for navigation back to bookingSheet
  let navigate = useNavigate();

  // Read the fixture with populated BookingSheet
  useEffect(() => {
    console.log(`fetching fixture data for fixtureID ${fixtureId}`);

    // Note: Axios makes a query URL if you set the params object as shown below
    // This creates an API call like /getBookingSheet?id=5fb10732d147ed4358eb55a4
    //setFixtureIsLoaded(false);
    // First define fetchData() function
    const fetchData = async () => {
      setFixtureReady(false); // more processing after the load
      try {
        // get the fixture from MongoDB WITH bookingSheet populated
        //console.log(`Getting fixture ${fixtureId}  from MongoDB`);
        const FIXTURE_URL = `http://${ARMY_NODE_SERVER_HOST}:${ARMY_NODE_SERVER_PORT}/fixtures/findById`;
        const response = await axios({
          method: "GET",
          withCredentials: true,
          url: FIXTURE_URL,
          params: { id: fixtureId },
        });

        const { fixture } = response.data; //destructuring
        //console.log(Array.isArray(fixture.bookingSheetRef.attendees));
        //save the fixture data in state
        setBookingData({
          course: fixture.course,
          eventDate: fixture.eventDate,
          bookingSheetId: fixture.bookingSheetRef._id,
          competition: fixture.competition,
          sponsor: fixture.sponsor,
          teeOffTime: fixture.teeOffTime,
        });
        setRawPlayerList(fixture.bookingSheetRef.attendees);
        setFixtureIsLoaded(true);
      } catch (error) {
        handleAxiosError(error);
      }
    }; // end of fetchdata() definition
    fetchData();
  }, [fixtureId, loggedIn, forceReload]); // force reload is used when user clears the timesheet

  // This triggers after the fixture data is loaded
  useEffect(() => {
    let tempPlayerPairs = []; // temp structure before setting state
    let tempFlights = []; // temp structure before setting state
    let tempFlightCount = 0; //

    //console.log(rawPlayerList);

    setFixtureReady(false);

    // print out the raw timeSheet data from Mongo
    //console.log("---------------------");
    //rawPlayerList.forEach((player) => {
    //  console.log(player.memberName, player.flightTime);
    //});

    // Function to make playerPairs for bookings display
    function makePlayerPairArray(playerList) {
      let playerPair = [];
      let sortedPlayers = _.sortBy(playerList, "cartId");

      sortedPlayers.forEach((player, index) => {
        if (playerPair.length === 0) {
          // then its a new tempPlayerPairs row
          playerPair.push(player); // save on left hand side
          if (player.cartId === null) {
            // its not a cart so we push is and move to next row
            tempPlayerPairs.push(playerPair);
            playerPair = [];
          }
        } else {
          // then we have saved a left side with a cartId saved
          if (player.cartId === null) {
            // then left and right hand side are independent
            tempPlayerPairs.push(playerPair); // push the saved one
            playerPair = [];
            playerPair.push(player);
            tempPlayerPairs.push(playerPair); // push this also because it not a cart
            playerPair = [];
          } else {
            // The new player had an id
            if (player.cartId === playerPair[0].cartId) {
              // this is the a matching cart share pair
              playerPair.push(player);
              tempPlayerPairs.push(playerPair); // push the pair
              playerPair = [];
            } else {
              // have a cart ID but not a matching one
              tempPlayerPairs.push(playerPair); // so push the left side
              playerPair = [];
              playerPair.push(player); // save a new left half
            }
          }
        }
        // we need to clean up if the last playerPair was an unshared cartId
        if (index + 1 === sortedPlayers.length && playerPair.length === 1) {
          tempPlayerPairs.push(playerPair);
        }
      });
    }

    // function to make the flights
    function makeFlighsFromPlayerPairs() {
      function getFlightDataFromPlayerPair(player, row, posn) {
        // local function to assemble the data for a single flight position - eg flight 1, posn 3
        // posn is either 0 (left) or 1 (right if cart is shared)
        let colonPos = player.flightTime.indexOf(":");
        let flightRow = parseInt(player.flightTime.substr(0, colonPos));
        let flightPosn = parseInt(player.flightTime.substr(colonPos + 1, 1));
        let flightData = {};
        flightData.memberName = player.memberName;
        flightData.hasCart = player.cartNeeds === "walk" ? false : true;
        flightData.playerRow = row;
        flightData.playerPosn = posn;
        flightData.flightRow = flightRow;
        flightData.flightPosn = flightPosn;
        return flightData;
      }

      // initialialise the flightTime in each playerPair
      let players = 0; // to count no of players and work out flights needed
      tempPlayerPairs.forEach((playerPair) => {
        players += playerPair.length;
        playerPair.forEach((player) => {
          if (player.flightTime === undefined) player.flightTime = ":";
          // "-" means not assigned otherwise "row:col"
        });
      });

      tempFlightCount = Math.ceil(players / 4) + 6;

      // initialise all flights to be empty (in case just a few are occupied)
      for (let flight = 0; flight < tempFlightCount; flight++) {
        let tempFlight = [];
        for (let pos = 0; pos < 4; pos++) {
          tempFlight.push({
            memberName: "-",
            playerRow: BAD_FLIGHT,
            playerPosn: BAD_FLIGHT,
            hasCart: false,
          });
          // playerRow is the playerPair assigned to the flight (BAD_FLIGHT=not assigned)
          // playerPosn is left posn 0 or rightPosn 1 (BAD_FLIGHT=not assigned)
        }
        tempFlights.push({ posn: tempFlight });
      }

      // Set the occupied flight positions
      for (let row = 0; row < tempPlayerPairs.length; row++) {
        if (tempPlayerPairs[row][0].flightTime !== ":") {
          let flightData = getFlightDataFromPlayerPair(tempPlayerPairs[row][0], row, 0);
          let flightRow = flightData.flightRow;
          delete flightData.flightRow;
          let flightPosn = flightData.flightPosn;
          delete flightData.flightPosn;
          tempFlights[flightRow].posn[flightPosn] = flightData;
        }
        if (tempPlayerPairs[row].length === 2) {
          if (tempPlayerPairs[row][1].flightTime !== ":") {
            let flightData = getFlightDataFromPlayerPair(tempPlayerPairs[row][1], row, 1);
            let flightRow = flightData.flightRow;
            delete flightData.flightRow;
            let flightPosn = flightData.flightPosn;
            delete flightData.flightPosn;
            tempFlights[flightRow].posn[flightPosn] = flightData;
          }
        }
      }
    }

    // here is the entry point
    if (fixtureIsLoaded) {
      makePlayerPairArray(rawPlayerList); // polulates tempPlayerPairs[]
      makeFlighsFromPlayerPairs(); // populates tempFlights[] and tempFlightCount

      // finally set the state variables
      setPlayerPairs(tempPlayerPairs);
      setFlights(tempFlights);
      setFlightCount(tempFlightCount);
      setFixtureReady(true);
    }
  }, [fixtureIsLoaded, rawPlayerList, flightCount]);

  // This callback assigns players to the currently selected flight (selectedFlight)
  // If there is one player in the playerPair, then find the first available spot
  // If there are two players, we need 2 flight spaces free, either 0,1 or 2,3
  // Note: The click is on the playerPairs side (left)
  async function handleSelectPlayerPair(row) {
    // "row" is the PlayerPair row selected
    // If the playerPair already has a flightTime, then we ignore the click
    if (playerPairs[row][0].flightTime !== ":") return;
    if (selectedFlight === BAD_FLIGHT) return; // no flight selected yet

    // make local copies of the flights and playerPairs state variables
    let tempFlights = flights.map((flight) => {
      return flight;
    });
    let tempPlayerPairs = playerPairs.map((playerPair) => {
      return playerPair;
    });

    function getFlightDataFromPlayer(row, col) {
      let flightData = {};
      flightData.memberName = playerPairs[row][col].memberName;
      flightData.hasCart = playerPairs[row][col].cartNeeds === "walk" ? false : true;
      flightData.playerRow = row;
      flightData.playerPosn = col;
      return flightData;
    }

    // check if the selected flight can fit another player or a pair
    if (playerPairs[row].length === 1) {
      // find the first free flight space and allocate it
      // each flight has an "selected" attribute and an array of 4 "posn" of {name: name}
      for (let n = 0; n < 4; n++) {
        if (tempFlights[selectedFlight].posn[n].memberName !== "-") continue; // skip if full
        let flightData = getFlightDataFromPlayer(row, 0);
        tempFlights[selectedFlight].posn[n] = flightData;
        tempPlayerPairs[row][0].flightTime = `${selectedFlight}:${n}`;
        break; // exit the loop when teh flight position is filled
      }
    } else {
      // this is a pair of players sharing a cart
      // so we need either first or second half of flight to be free
      if (
        tempFlights[selectedFlight].posn[0].memberName === "-" &&
        tempFlights[selectedFlight].posn[1].memberName === "-"
      ) {
        let flightData;
        for (let n = 0; n < 2; n++) {
          flightData = getFlightDataFromPlayer(row, n);
          tempFlights[selectedFlight].posn[n] = flightData;
        }
        tempPlayerPairs[row][0].flightTime = `${selectedFlight}:${0}`;
        tempPlayerPairs[row][1].flightTime = `${selectedFlight}:${1}`;
      } else {
        if (
          tempFlights[selectedFlight].posn[2].memberName === "-" &&
          tempFlights[selectedFlight].posn[3].memberName === "-"
        ) {
          let flightData;
          for (let n = 0; n < 2; n++) {
            flightData = getFlightDataFromPlayer(row, n);
            tempFlights[selectedFlight].posn[n + 2] = flightData;
          }
          tempPlayerPairs[row][0].flightTime = `${selectedFlight}:${2}`;
          tempPlayerPairs[row][1].flightTime = `${selectedFlight}:${3}`;
        }
      }
    }
    // save the changes to Mongo
    await saveUpdatedFlightTime(tempPlayerPairs[row]);
    // Set the state variables to induce a redraw
    setPlayerPairs(tempPlayerPairs);
    setFlights(tempFlights);
  }

  // playerPair is structured like this, and playerQuad is the same, but 4 elements in the array
  // playerPair: [ { name: name, mode: mode},{ name: name, mode: mode}]
  // Display a bookingSheet row on the left
  function BookingSheetRow({ playerPair, index }) {
    return (
      <Box display="flex" flexDirection="row" flex="100%">
        <Box
          display="flex"
          flexDirection="row"
          flex="50%"
          className={playerPair[0].flightTime === ":" ? classes.noFlight : classes.flight}
          onClick={() => handleSelectPlayerPair(index)}
        >
          <Box flex="20%">
            <Icon
              path={
                playerPair[0].cartNeeds === "shared" || playerPair[0].cartNeeds === "unshared"
                  ? mdiGolfCart
                  : mdiWalk
              }
              className={classes.golfIcon}
            />
          </Box>
          <Box flex="80%">{playerPair[0].memberName}</Box>
        </Box>

        {playerPair.length > 1 ? (
          <Box
            display="flex"
            flexDirection="row"
            flex="50%"
            className={playerPair[0].flightTime === ":" ? classes.noFlight : classes.flight}
            onClick={() => handleSelectPlayerPair(index)}
          >
            <Box flex="20%">
              <Icon
                path={playerPair[1].cartNeeds === "shared" ? mdiGolfCart : mdiWalk}
                className={classes.golfIcon}
              />
            </Box>
            <Box flex="80%">{playerPair[1].memberName}</Box>
          </Box>
        ) : (
          <Box display="flex" flexDirection="row" flex="50%" className={classes.noFlight}>
            <Box></Box>
          </Box>
        )}
      </Box>
    );
  }

  // This call back has 2 functions
  // if we click on an empty flight position,that flight becomes the selected flight
  // if we click on a flight that is occupied the player or pair is
  // is removed frm the flight
  async function handleSelectFlight(flightNo, posn) {
    if (posn === 4 || selectedFlight === BAD_FLIGHT || selectedFlight !== flightNo) {
      // this means we clicked on the flight no
      setSelectedFlight(flightNo);
      return;
    }

    if (flights[flightNo].posn[posn].memberName === "-") {
      // we clicked on a flight position and its empty
      setSelectedFlight(flightNo);
      return;
    }

    // if we reach here we have clicked on an occupied flight position
    // and so we will need to update both flights and playerPairs
    let tempFlights = flights.map((flight) => {
      return flight;
    });

    let tempPlayerPairs = playerPairs.map((playerPair) => {
      return playerPair;
    });

    // find the playerPair
    let row = tempFlights[flightNo].posn[posn].playerRow;
    let col = tempFlights[flightNo].posn[posn].playerPosn;

    // a very impure local function
    function clearFlight(flightNo, posn) {
      tempFlights[flightNo].posn[posn].memberName = "-";
      tempFlights[flightNo].posn[posn].hasCart = false;
      tempFlights[flightNo].posn[posn].playerRow = BAD_FLIGHT;
      tempFlights[flightNo].posn[posn].playerPosn = BAD_FLIGHT;
    }

    if (tempPlayerPairs[row].length === 1) {
      tempPlayerPairs[row][0].flightTime = ":";
      clearFlight(flightNo, posn);
    } else {
      // Its a filled cart pair
      if (col === 1) posn--; // go to the left hand cart position, if not already there
      for (let n = 0; n < 2; n++) {
        tempPlayerPairs[row][n].flightTime = ":"; // remove the flight coords from playerPair
        clearFlight(flightNo, posn + n);
      }
    }
    // save the changes to Mongo
    await saveUpdatedFlightTime(tempPlayerPairs[row]);
    // finally set the state variables
    setPlayerPairs(tempPlayerPairs);
    setFlights(tempFlights);
  }

  // This component displasy the flights
  function Flights() {
    //Display a flight row
    function FlightRow({ flight, row }) {
      return (
        <Box
          display="flex"
          flexDirection="row"
          flex="100%"
          className={row === selectedFlight ? classes.quadSelected : classes.quadUnSelected}
        >
          <Box flex="4%" className={classes.flightNo} onClick={() => handleSelectFlight(row, 4)}>
            {row + 1}
          </Box>
          <Box
            flex="24%"
            className={classes.row}
            display="flex"
            flexDirection="row"
            onClick={() => handleSelectFlight(row, 0)}
          >
            <Box flex="10%">
              {flight.posn[0].hasCart ? (
                <Icon path={mdiGolfCart} className={classes.golfIcon} />
              ) : null}
            </Box>
            <Box flex="90%">
              {flight.posn[0].memberName === "-" ? "" : flight.posn[0].memberName}
            </Box>
          </Box>
          <Box
            flex="24%"
            className={classes.row}
            display="flex"
            flexDirection="row"
            onClick={() => handleSelectFlight(row, 1)}
          >
            <Box flex="10%">
              {flight.posn[1].hasCart ? (
                <Icon path={mdiGolfCart} className={classes.golfIcon} />
              ) : null}
            </Box>
            <Box flex="90%">
              {flight.posn[1].memberName === "-" ? "" : flight.posn[1].memberName}
            </Box>
          </Box>
          <Box
            flex="24%"
            className={classes.row}
            display="flex"
            flexDirection="row"
            onClick={() => handleSelectFlight(row, 2)}
          >
            <Box flex="10%">
              {flight.posn[2].hasCart ? (
                <Icon path={mdiGolfCart} className={classes.golfIcon} />
              ) : null}
            </Box>
            <Box flex="90%">
              {flight.posn[2].memberName === "-" ? "" : flight.posn[2].memberName}
            </Box>
          </Box>
          <Box
            flex="24%"
            className={classes.row}
            display="flex"
            flexDirection="row"
            onClick={() => handleSelectFlight(row, 3)}
          >
            <Box flex="10%">
              {flight.posn[3].hasCart ? (
                <Icon path={mdiGolfCart} className={classes.golfIcon} />
              ) : null}
            </Box>
            <Box flex="90%">
              {flight.posn[3].memberName === "-" ? "" : flight.posn[3].memberName}
            </Box>
          </Box>
        </Box>
      );
    }

    return (
      <>
        {flights.map((flight, index) => {
          return (
            <Box key={uuidv4()} display="flex" flexDirection="row">
              <FlightRow flight={flight} row={index}></FlightRow>
            </Box>
          );
        })}
      </>
    );
  }

  // this callback clears all the flight allocations for the bookingSheet
  // ie sets flightTime=":" for all attendees
  // allows user to restart from scratch if needed.
  async function handleClearButtonClick() {
    try {
      //Pass the search criteria in req.data
      console.log(`Updated flightTime in MongoDB in bookingSheet ${bookingData.bookingSheetId}`);
      const UPDATE_ATTENDEE_URL = `http://${ARMY_NODE_SERVER_HOST}:${ARMY_NODE_SERVER_PORT}/bookingsheets/clearTimesheetAllocations`;
      await axios({
        method: "PUT",
        withCredentials: true,
        url: UPDATE_ATTENDEE_URL,
        data: {
          // data is put in req.body by Axios
          bookingSheetId: bookingData.bookingSheetId, // which bookingSheet to update
        },
      });
    } catch (error) {
      //now force a display update
      handleAxiosError(error);
    } finally {
      setSelectedFlight(BAD_FLIGHT);
      if (forceReload) {
        setForceReload(false);
      } else {
        setForceReload(true);
      }
    }
  }

  //This function updates one attendees flight time
  async function saveUpdatedFlightTime(playerPair) {
    // There may be 2 players in the flight so we update both of them
    let promises = playerPair.map(async (player) => {
      try {
        //Pass the search criteria in req.data
        console.log(`Updated flightTime in MongoDB in bookingSheet ${bookingData.bookingSheetId}`);
        const UPDATE_ATTENDEE_URL = `http://${ARMY_NODE_SERVER_HOST}:${ARMY_NODE_SERVER_PORT}/bookingsheets/patchAttendee`;
        await axios({
          method: "PUT",
          withCredentials: true,
          url: UPDATE_ATTENDEE_URL,
          data: {
            // data is put in req.body by Axios
            bookingSheetId: bookingData.bookingSheetId, // where to save
            memberName: player.memberName,
            cartId: player.cartId, // only used for updateType ADD_PARTNER_CART_ID
            cartNeeds: player.cartNeeds, // noy used here
            potOfGold: player.potOfGold, // not user here
            flightTime: player.flightTime,
            updateType: CHANGE_FLIGHT_TIME, // 4 possible type of update ( see API for doco
          },
        });
      } catch (error) {
        handleAxiosError(error);
      } finally {
        //console.log(result);
      }
    });
    await Promise.all(promises);
  }

  // This component houses the list of attendees on the left
  // and the booking sheet on the right
  // on the left, a PlayerPair represents one player, or 2 if sharing a cart
  function Workspace() {
    const membersListRef = useRef(); // used for printing
    return (
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column" flex="32.5%">
          <Typography variant="h5" style={{ textAlign: "center" }}>
            Registered Players
          </Typography>
          {playerPairs.map((playerPair, index) => {
            return (
              <Box key={uuidv4()} display="flex" flexDirection="row">
                <BookingSheetRow playerPair={playerPair} index={index}></BookingSheetRow>
              </Box>
            );
          })}
        </Box>
        <Box flex="2%"></Box>
        <Box display="flex" flexDirection="column" flex="65.5%">
          <Box display="flex" flexDirection="row" justifyContent="space-evenly">
            <Box>
              <ReactToPrint
                trigger={() => <Button variant="contained">Print TimeSheet</Button>}
                content={() => membersListRef.current}
                //pageStyle format found in source of react-to-print
                pageStyle="@page { size: auto;  margin: 10mm; } @media print { body { -webkit-print-color-adjust: exact; } }"
              />
            </Box>
            <Box>
              <Tooltip
                arrow
                disableFocusListener
                disableTouchListener
                title={
                  <span style={{ fontSize: "14px" }}>
                    Click if you want to clear the timesheet and start again
                  </span>
                }
              >
                <Button variant="contained" onClick={() => handleClearButtonClick()}>
                  Clear TimeSheet
                </Button>
              </Tooltip>
            </Box>
          </Box>

          <div ref={membersListRef}>
            {/** stuff to be printed must be child of <div> as here */}

            <TitleBox
              bookingData={bookingData}
              attendeeCount={rawPlayerList.length}
              cartCount={cartsCount()}
            ></TitleBox>

            <Typography variant="h5" style={{ textAlign: "center" }}>
              Captain's Timesheet Allocations
            </Typography>
            <Flights></Flights>
          </div>
        </Box>
      </Box>
    );
  }

  // utility to count no of carts
  function cartsCount() {
    let count = 0;
    rawPlayerList.forEach((attendee) => {
      if (attendee.cartNeeds === "shared") count += 0.5;
      else if (attendee.cartNeeds === "unshared") count++;
    });
    return count;
  }

  return (
    <Box>
      {fixtureReady === true ? <Workspace></Workspace> : <p>Loading ......</p>}
      <>
        <div className={classes.backJustify}>
          <Link to={`/BookingSheet/${bookingData.bookingSheetId}`}>
            <ArrowBackIcon className={classes.backIcon} />
            <span onClick={() => navigate(-1)} className={classes.backButton}>
              Back to BookingSheet
            </span>
          </Link>
        </div>
        <div className={classes.backJustify}>
          <Link to="/AboutGolf/Fixtures">
            <ArrowBackIcon className={classes.backIcon} />
            <span className={classes.backButton}> Back to Fixtures</span>
          </Link>
          <span> </span>
        </div>
      </>
    </Box>
  );
}

export default MakeTimesheet;
