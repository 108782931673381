import React from "react";
import Box from "@material-ui/core/Box";

function LoginMiClub() {
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="center">
        Click on link below for MiClub booking
      </Box>
      <Box display="flex" justifyContent="center">
        <a
          href="https://armygolfclubwa.1golf.com.au/security/login.msp"
          rel="noreferrer"
          target="_blank"
          style={{ fontSize: "1.5em" }}
        >
          Login to MiClub booking
        </a>
      </Box>
    </Box>
  );
}
export default LoginMiClub;
