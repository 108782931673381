import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Tooltip from "@material-ui/core/Tooltip";
import { setWithExpiry } from "../utils/localStorage.js";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import LoggedInContext from "../components/loggedInContext.js";
import { handleAxiosError } from "../utils/handleAxiosError.js";
import axios from "axios";
import ADDRESSES from "../IPandPorts.js";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "400px",
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(3),
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid black",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  badLogin: {
    color: "red",
    fontSize: ".8em",
  },
  clickItems: {
    color: "red",
    fontSize: ".7em",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  dialogBox: {
    //backgroundColor: "#d5e4d7", // light green
    padding: "15px",
    border: "2px solid black",
    borderRadius: "5px",
  },
  changePWInstructions: {
    paddingLeft: "10px",
    color: "#3f51b5",
    fontSize: ".9em",
  },
  changePWButton: {
    margin: theme.spacing(1, 0, 1),
  },
}));

// NOTE: we cant use getenv with React - although we could load variable from a file
const ARMY_NODE_SERVER_HOST = ADDRESSES.IP;
const ARMY_NODE_SERVER_PORT = ADDRESSES.APIPort;

export default function Login() {
  const classes = useStyles();

  const { loggedIn, setLoggedIn } = useContext(LoggedInContext);

  const [userName, setUserName] = useState(""); // entry field
  const [password, setPassword] = useState(""); // entry field
  const [newPassword, setNewPassword] = useState(""); // entry field
  const [confirmNewPassword, setConfirmNewPassword] = useState(""); // entry field
  const [openSnackbar, setOpenSnackbar] = useState(false); // normally closed
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState(""); //error, warning, info, success

  const [openNewPWDialog, setOpenNewPWDialog] = useState(false); // Dialog is closd
  const [openForgotPWDialog, setOpenForgotPWDialog] = useState(false); //Dialog is closed

  let navigate = useNavigate();

  //useEffect(() => {
  //  // if already logged in just go to "/"
  //  console.log("logged In status inside useEffect", loggedIn);
  //  if (loggedIn.status) {
  //    navigate("/Home");
  //  }
  //}, [loggedIn, navigate]);

  // process the login when user clicks submit buttom
  async function handleLoginClickOld(e) {
    //console.log(`Name and password: ${userName} ${password}`);
    try {
      const LOGIN_URL = `http://${ARMY_NODE_SERVER_HOST}:${ARMY_NODE_SERVER_PORT}/login`;
      const res = await axios({
        method: "POST",
        data: { username: userName, password: password },
        withCredentials: true,
        url: LOGIN_URL,
      });
      if (res.data.username) {
        console.log(res.data);
        //setData(res.data); // either login is good or bad
        setLoggedIn({
          status: true,
          user: res.data.username,
          golfLink: res.data.golfLink,
          role: res.data.role,
        });
        navigate("/Home");
      } else {
        setSnackbarSeverity("error");
        setSnackbarMessage("Login failed: name or password not correct");
        setOpenSnackbar(true);
      }
    } catch (error) {
      handleAxiosError(error);
    }
  }

  // process the login when user clicks submit buttom
  // simplified for golfa/bentputter
  async function handleLoginClick(e) {
    if (userName === "golfa" && password === "bentputter") {
      setLoggedIn({
        status: true,
        user: userName,
      });
      setWithExpiry("loggedIn", true, 365 * 60 * 60 * 24 * 1000); // 1 year
      navigate("/Home");
    } else {
      setSnackbarSeverity("error");
      setSnackbarMessage("Login failed: name or password not correct");
      setOpenSnackbar(true);
    }
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  // update the state as the user types the username
  function handleUserNameInput(e) {
    setUserName(e.target.value);
  }
  // update the state as the user types the password
  function handlePasswordInput(e) {
    setPassword(e.target.value);
  }
  // update the state as the user types the password
  function handleNewPasswordInput(e) {
    setNewPassword(e.target.value);
  }
  // update the state as the user types the confirmed password
  function handleConfirmNewPasswordInput(e) {
    setConfirmNewPassword(e.target.value);
  }

  // Process the change password when user confirms
  async function handleChangePWClick() {
    console.log(`change pasword for ${userName} : ${password}`);
    // The step needed here are
    // 1. Verify the supplied name and password in the database
    // 2. if good check that the new password matches the confirmed
    // 3. and they meet the 3char, 3num minimum
    // 4  If all good, update the password

    //Step 1. Verify the supplied name and password in the database
    try {
      let res;
      const CHECK_CREDENTIALS_URL = `http://${ARMY_NODE_SERVER_HOST}:${ARMY_NODE_SERVER_PORT}/checkCredentials`;
      res = await axios({
        method: "POST",
        data: { username: userName, password: password },
        withCredentials: true,
        url: CHECK_CREDENTIALS_URL,
      });
      if (res.data.user) {
        // user is only sent if verified
        console.log(`User ${res.data.user.lastName} is verified`);
      } else {
        setSnackbarMessage("User name or password are incorrect");
        setSnackbarSeverity("warning");
        setOpenSnackbar(true);
        return;
      }
    } catch (error) {
      handleAxiosError(error);
    }

    // step 2. verify new passwords are the same
    if (newPassword !== confirmNewPassword) {
      setSnackbarMessage("New passwords dont match");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }

    // step 3. verify new passowrds meet 5 chars criteria
    else if (!confirmFormat(newPassword)) {
      setSnackbarMessage("Password must contain at least 5 characters");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    } else {
      // step 4. save the new password to Mongo and alert user is done
      try {
        let res;
        const CHECK_CREDENTIALS_URL = `http://${ARMY_NODE_SERVER_HOST}:${ARMY_NODE_SERVER_PORT}/patchUserPassword`;
        res = await axios({
          method: "POST",
          data: { username: userName, password: newPassword },
          withCredentials: true,
          url: CHECK_CREDENTIALS_URL,
        });
        if (res.data.user) {
          // user is only sent by api if password is updated
          setSnackbarMessage(`${userName} may now login with new password`);
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
          setOpenNewPWDialog(false);
        } else {
          setSnackbarMessage(res.data.message);
          setSnackbarSeverity("warning");
          setOpenSnackbar(true);
        }
      } catch (error) {
        handleAxiosError(error);
      }
    }
  } // end handleChangePWClick()

  // this is a helper to confirm the new password format
  // we are making this simple for now - just has to be at least 5 chars long
  function confirmFormat(pw) {
    if (pw.length < 5) return false;
    //let digitCount = 0;
    //let letterCount = 0;
    //let thisChar;
    //for (var i = 0; i < pw.length; i++) {
    //  thisChar = pw[i];
    //  if (thisChar >= "0" && thisChar <= "9") digitCount++;
    //  if ((thisChar >= "a" && thisChar <= "z") || (thisChar >= "A" && thisChar <= "Z"))
    //    letterCount++;
    //}
    //console.log(digitCount, letterCount);
    //if (digitCount < 3 || letterCount < 3) return false;
    return true;
  }

  // same cancel function for both dialogs
  function handleCancelClick() {
    setOpenNewPWDialog(false);
    setOpenForgotPWDialog(false);
  } // end handleCancelClick()

  return (
    <>
      {/** ***************Snackbar to display confirmation of PW change */}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      {/** end of snackbar */}

      {/** ******************* Dialogs that pop up on request *******************
            Note. Dialogs are mounted as children of the document <body> and when activated are 
              placed at the center of the screen */}
      <Dialog // This Dialog is to change to a new password
        onClose={() => {
          setOpenNewPWDialog(false);
        }}
        open={openNewPWDialog} // this state is initially false (dialog closed)
        aria-labelledby="new-password"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          className={classes.dialogBox}
          maxWidth="300px"
        >
          <Box
            display="flex"
            flexDirection="column"
            style={{ border: "1px solid black", borderRadius: "10px" }}
          >
            <div className={classes.changePWInstructions}>
              - To change password, enter your current (name,password) and then
              enter your new password and confirm it.
            </div>
            <div
              style={{ paddingTop: "2px", paddingBottom: "2px" }}
              className={classes.changePWInstructions}
            >
              - Your new password should contain at least 3 letters and 3
              numbers in any order.
            </div>
            <div className={classes.changePWInstructions}>
              - Then you can log in with the new password.
            </div>
          </Box>

          <form className={classes.form} noValidate>
            <Box
              style={{
                border: "1px solid black",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="userName"
                label="User Name"
                name="User Name"
                autoFocus
                onChange={handleUserNameInput}
                size="small"
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Current password"
                type="password"
                id="password"
                onChange={handlePasswordInput}
                size="small"
              />
            </Box>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="New password"
              label="New password"
              type="password"
              id="password"
              onChange={handleNewPasswordInput}
              size="small"
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="Confirm new password"
              label="Confirm new password"
              type="password"
              id="password"
              onChange={handleConfirmNewPasswordInput}
              size="small"
            />

            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleChangePWClick}
              className={classes.changePWButton}
            >
              Confirm password change
            </Button>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleCancelClick}
              className={classes.changePWButton}
            >
              Cancel
            </Button>
          </form>
        </Box>
      </Dialog>
      {/** // end Dialog is to change to a new password */}

      <Dialog // This Dialog is to provide a new once off passwordd in case forgotten
        onClose={() => {
          setOpenForgotPWDialog(false);
        }}
        open={openForgotPWDialog} // this state is initially false (dialog closed)
        aria-labelledby="forgot-member"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          className={classes.dialogBox}
        >
          <Box>NOT IMPLEMENTED YET</Box>
        </Box>
      </Dialog>
      <Container className={classes.container}>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography align="center" component="h1" variant="h5">
            Army Golf Club Members Sign in
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="userName"
              label="User Name"
              name="User Name"
              autoFocus
              onChange={handleUserNameInput}
              size="small"
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={handlePasswordInput}
              size="small"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}

            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleLoginClick}
              className={classes.submit}
            >
              Sign In
            </Button>
            {/* <Box width="100%" display="flex" justifyContent="space-between">
              <Box display="flex">
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  title="Click here to change password"
                >
                  <span className={classes.clickItems} onClick={() => setOpenNewPWDialog(true)}>
                    Change Password
                  </span>
                </Tooltip>
              </Box>

              <Box display="flex">
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  title="Click here if you forgot password"
                >
                  <span className={classes.clickItems} onClick={() => setOpenForgotPWDialog(true)}>
                    Forgot Password
                  </span>
                </Tooltip>
              </Box>
            </Box> */}
          </form>
        </div>
      </Container>
    </>
  );
}
