import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ThemeProvider, createTheme } from "@material-ui/core/styles"; //Enables use of the default theme everywhere
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

// This is just the default theme
const theme = createTheme();

// the theme is customised in theme.js and imported here.
// the customised theme is then available to all components inside the
// <ThemeProvider> component.
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById("root")
);
