import React, { useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import LoggedInContext from "../../components/loggedInContext.js";
import { ADMIN } from "../../constants";
import { v4 as uuidv4 } from "uuid";
import IconButton from "@material-ui/core/IconButton";
// Icon and components from "materialdesignicons.com" (@mdi/)
import { Icon } from "@mdi/react";
import { mdiGolfCart } from "@mdi/js"; // to indicate golf cart needed
import { mdiWalk } from "@mdi/js"; // to indicate walking
import { mdiClose } from "@mdi/js";
import { mdiPot } from "@mdi/js"; // for pot of gold

const useStyles = makeStyles((theme) => ({
  itemContainer: {
    width: "100%",
  },
  attendeeBox: {
    border: "1px dotted black",
    justifyContent: "space-between",
    width: "50%",
  },
  emptyRightPos: {
    border: "1px dotted black",
    justifyContent: "space-evenly",
    width: "50%",
  },
  firstRow: {
    textAlign: "center",
    border: "1px dotted black",
    width: "50%",
  },
  memberName: { paddingLeft: "5px" },
  memberNameHighlighted: {
    paddingLeft: "5px",
    fontWeight: "bold",
    fontStyle: "italic",
  },
  cartTitle: {
    fontWeight: "bold",
  },
  buttonRoot: {
    padding: "0px",
  },
  iconBox: {
    paddingLeft: "5px",
  },
  iconRoot: {
    fontSize: "1.2rem",
  },
  golfIcon: {
    paddingTop: "2px",
    paddingBottom: "2px",
    height: "18px",
    width: "22px",
  },
  golfIconEditable: {
    paddingTop: "2px",
    paddingBottom: "2px",
    height: "18px",
    width: "22px",
    backgroundColor: "#cfe0df", // very light green to indicate can be edited
  },
  closeIcon: {
    // make this one smaller
    paddingTop: "2px",
    paddingBottom: "2px",
    height: "12px",
    width: "16px",
  },
  textButton: {
    // easier to control size than mui <Button>
    fontFamily: "Arial",
    fontSize: "1em",
    fontWeight: "bold",
    //textAlign: "center",
    margin: "2px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  listTitle: {
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: ".8em",
    color: "blue",
    textAlign: "center",
    border: "1px dotted black",
  },
}));

// Component <AttendeeList> to display the list of attendees and cart needs
// An attendee is an object like {memberName:String, cartNeeds:"walk"}
function DisplayAttendeeList({
  attendeeRows,
  unFullCarts,
  handleDeleteAttendee,
  handleOpenDialog,
  handleOpenCartDialog,
  handleOpenCartOptionDialog,
}) {
  // attendees[] is passed in - it is already sorted
  // first by cartNeeds, then by cart_id

  const classes = useStyles();

  // loggedIn looks like {status: true|false, user: name, golfLink:"10 digit no"}
  const { loggedIn } = useContext(LoggedInContext);

  // This is a local component to display one attendee
  // on a row of attendees. Row can have 1 or 2 attendees
  // depending on sharing logic. Later a filght of 4 may happen
  function DisplayOneAttendee({ attendee }) {
    return (
      <Box // surrounds the position (attendee or button or blank)
        key={uuidv4()}
        display="flex"
        className={classes.attendeeBox}
      >
        <Tooltip disableFocusListener disableTouchListener title="Click to change options">
          <Box // this <Box> surrounds the option icons
            display="flex"
            alignItems="center"
            flex=".1 .1 0"
            className={classes.iconBox}
          >
            {attendee.cartNeeds === "walk" ? (
              // walking
              <Icon
                path={mdiWalk}
                className={editAllowed(attendee) ? classes.golfIconEditable : classes.golfIcon}
                onClick={() => handleOpenCartOptionDialog(attendee)}
              />
            ) : attendee.cartNeeds === "shared" ? (
              // shared
              <Icon
                path={mdiGolfCart}
                style={{ color: attendee.colour }}
                className={editAllowed(attendee) ? classes.golfIconEditable : classes.golfIcon}
                onClick={() => handleOpenCartOptionDialog(attendee)}
              />
            ) : (
              // unshared
              <Icon
                path={mdiGolfCart}
                style={{ color: attendee.colour }}
                className={editAllowed(attendee) ? classes.golfIconEditable : classes.golfIcon}
                onClick={() => handleOpenCartOptionDialog(attendee)}
              />
            )}
            {attendee.potOfGold ? (
              <Icon
                path={mdiPot}
                style={{ color: "#f2c206" }}
                className={classes.golfIcon}
                onClick={() => handleOpenCartOptionDialog(attendee)}
              />
            ) : (
              <Box
                onClick={() => handleOpenCartOptionDialog(attendee)}
                className={classes.golfIcon}
              /> // empty box
            )}
          </Box>
        </Tooltip>
        <Box // surrounds attendee name
          flex="0.6 0.6 0"
          className={
            attendee.memberName === loggedIn.user
              ? classes.memberNameHighlighted
              : classes.memberName
          }
        >
          {attendee.memberName}
        </Box>
        <Box // This <Box> surrounds the <CancelIcon>
          flex=".1 .1 0"
        >
          {editAllowed(attendee) ? ( // only display the close Icon if editing allowed
            <>
              <Tooltip disableFocusListener disableTouchListener title="Click to cancel booking">
                <IconButton
                  classes={{ root: classes.buttonRoot }}
                  aria-label="delete"
                  onClick={() => handleDeleteAttendee(attendee)}
                >
                  <Icon path={mdiClose} style={{ color: "red" }} className={classes.closeIcon} />
                </IconButton>
              </Tooltip>
            </>
          ) : null}
        </Box>
      </Box>
    );
  }

  // helper to check if attendee is allowed to edit/delete an item
  function editAllowed(attendee) {
    if (
      attendee.bookedBy === loggedIn.user || // can edit if member made the booking
      loggedIn.role === ADMIN || // can edit is admin
      attendee.memberName === loggedIn.user // can edit if the attendee is the logged in user
    )
      return true;
    return false;
  }

  // This controls the display of empty right hand positions
  // that can hold either
  // a sharable cart {cartNeeds:"shared"}
  // walker or non sharable cart {cartNeeds:"walk"} || {cartNeeds:"unshared"}
  function DisplayEmptyRightPosition({ leftAttendee }) {
    return (
      <Box // surrounds the position (button or blank)
        display="flex"
        key={uuidv4()}
        className={classes.emptyRightPos}
      >
        {leftAttendee.cartNeeds === "shared" ? (
          <Tooltip
            disableFocusListener
            disableTouchListener
            title={
              <>
                <div style={{ fontSize: "1.5em" }}>Click to register and</div>
                <div style={{ fontSize: "1.5em" }}>Share this cart</div>
              </>
            }
          >
            <Box
              className={classes.textButton}
              // we need to block this if the bookeddBy count >= 2 for the loggedIn
              // member (except if amdin user)
              onClick={() => handleOpenDialog(leftAttendee)}
            >
              [ Share Cart ]
            </Box>
          </Tooltip>
        ) : null}
        {leftAttendee.cartNeeds === "shared" &&
        loggedIn.role === ADMIN &&
        unFullCarts.length > 1 ? (
          <Tooltip
            disableFocusListener
            disableTouchListener
            title="Admin Only: Click to select player to share this cart"
          >
            <Box
              className={classes.textButton}
              // This is only for the Admin user
              onClick={() => handleOpenCartDialog(leftAttendee)}
            >
              [ fill cart ]
            </Box>
          </Tooltip>
        ) : null}
      </Box>
    );
  }

  // Display the instructions
  function DisplayTitleRow() {
    return (
      <Box // This <Box> surrounds the 2 inner text boxes
        display="flex"
        className={classes.itemContainer}
      >
        <Box width="50%" className={classes.listTitle}>
          (Individual bookings this side)
        </Box>
        <Box width="50%" className={classes.listTitle}>
          (...or choose a cart buddy this side)
        </Box>
      </Box>
    );
  }

  // This is always the first row
  // To allow anyone to make a booking - open a booking position
  function DisplayFirstRow() {
    return (
      <Box // This <Box> surrounds the whole line
        display="flex"
        width="100%"
      >
        <Box className={classes.firstRow}>
          <Tooltip
            disableFocusListener
            disableTouchListener
            title={
              <>
                <div style={{ fontSize: "1.5em" }}>Click to walk or</div>
                <div style={{ fontSize: "1.5em" }}>Take an unshared cart</div>
              </>
            }
          >
            <Box
              className={classes.textButton}
              // we need to block this if the bookedBy count >= 2 for the loggedIn
              // member (except if amdin user)
              onClick={() => handleOpenDialog()}
            >
              [ Register ]
            </Box>
          </Tooltip>
        </Box>
        <Box // this is empty for now
          className={classes.firstRow}
        ></Box>
      </Box>
    );
  }

  //when reaching here we can expect the list of members to be arranged
  //as a list with 2 columns (attendeeRows[])- with playes on the right sharing cart with left
  // each row[] therefore has length 1 or 2 (if a cart is shared)
  // the left and right are called "positions" so when we display a position
  // is passed the index so it knows its a left or right
  // the whole row[] is also passed to each position

  /**
   * Logic for who can register and choose a cart partner
   * 1) Any member can book and cancel himself
   * 2) Any member can book and cancel one other member
   * 3) Each booking as above can be made as a new booking row
   *    or sharing on an existing row (share cart only)
   *
   * WHen a player logs in there are 4 status possibilties
   *    ie all combinations of booked-self, booked-other
   *    case 1: Both booked - cancel icon shown against both
   *    case 2: Neither booked
   *            Is able for himself,
   *                Book as individual - can be walk, shared, unshared
   *                Books to share this cart - will be cart
   *            for another member
   *                Book as individual (select the member) - can be walk, shared, unshared
   *                Books to share (select the member) this cart - will be cart
   *    case 2: Booked self only
   *            gets
   */

  // return for <DisplayAttendeeList
  return (
    <>
      <DisplayTitleRow />
      <DisplayFirstRow // defined above - to [ book ] new player in left column
      />
      {attendeeRows.map((row) => (
        <Box // This <Box> surrounds the row
          key={uuidv4()}
          display="flex"
          className={classes.itemContainer}
          minHeight="22px"
        >
          <DisplayOneAttendee attendee={row[0]} />
          {row.length === 2 ? (
            <DisplayOneAttendee attendee={row[1]} />
          ) : (
            <DisplayEmptyRightPosition leftAttendee={row[0]} />
          )}
        </Box>
      ))}
    </>
  );
}

export default DisplayAttendeeList;
