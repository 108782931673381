/**
 * <Minutes> displays a list of minutes as read from Azure
 * It displays them as links to display the assocated PDF's
 * */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { getWithExpiry, setWithExpiry } from "../utils/localStorage";
import axios from "axios";
import dayjs from "dayjs";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);
var advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);

const useStyles = makeStyles((theme) => ({
  title: {
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      paddingBottom: "30px",
    },
  },
  titleText: {
    fontSize: "1em",
    [theme.breakpoints.up("md")]: {
      fontSize: "2em",
    },
  },
  subtitleText: {
    fontSize: ".7em",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.4em",
    },
  },
  headerText: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "1.5em",
    color: "green",
  },
  messageText: {
    fontSize: ".8em",
  },
}));

function Minutes() {
  const [token, setToken] = useState(""); // the MSGraph token for the registered Azure App
  const [committeeMins, setCommitteeMins] = useState([]);
  const [agmMins, setAgmMins] = useState([]);
  const [apiError, setApiError] = useState(false);

  // Get an Azure access token if localStorage version is expired (60 mins)
  useEffect(() => {
    async function getToken() {
      let token = getWithExpiry("token");
      if (token) {
        setToken(token);
      } else {
        const response = await API.get("apiGetOneDriveToken", "/oneDriveToken");
        console.log("response: ", response);
        setToken(response.access_token);
        setWithExpiry("token", response.access_token, 60 * 58 * 1000); // 58 mins in ms
      }
    }
    getToken();
  }); // need it to run ever time becasue the token can expire in localStorage

  // Read the list of matchReports from Azure is not already read
  useEffect(() => {
    async function getMinutes() {
      const FILE_URL = `https://graph.microsoft.com/v1.0/drives/b!R4T0Vmiqb02DBArVNy0jBEg-mU0lOudPh_QV0PmucwVIYgffNZ_lR4uHD2E51qNV/root:/httpdocs/armygolfclubwa.com/members/minutes:/children?$select=name,content.downloadUrl`;
      console.log("minutes URL", FILE_URL);
      let res = {};
      let committeeMins = [];
      let agmMins = [];
      try {
        res = await axios({
          method: "GET",
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
          url: FILE_URL,
        });
        console.log("res", res);
        for (const fileItem of res.data.value) {
          let namePart = fileItem.name.slice(0, 6);
          let datePart = fileItem.name.slice(6, 12); // should be 6 digits like YYMMDD
          if (!isNaN(parseInt(datePart))) {
            if (namePart === "COMMIN") {
              // push if filename looks like COMMINYYMMDD.pdf
              committeeMins.push({
                sortField: parseInt(datePart),
                dateString: dayjs(datePart, "YYMMDD").format("Do MMM YYYY"),
                fileName: fileItem.name,
                url: fileItem["@microsoft.graph.downloadUrl"],
              });
            } else if (namePart === "AGMMIN") {
              // push if filename looks like AGMMINYYMMDD.pdf
              agmMins.push({
                sortField: parseInt(datePart),
                dateString: dayjs(datePart, "YYMMDD").format("Do MMM YYYY"),
                fileName: fileItem.name,
                url: fileItem["@microsoft.graph.downloadUrl"],
              });
            }
          }
        }
        committeeMins.sort((a, b) => b.sortField - a.sortField);
        agmMins.sort((a, b) => b.sortField - a.sortField);
        console.log("committee Mins", committeeMins);
        console.log("agm Mins Mins", agmMins);
        setCommitteeMins(committeeMins);
        setAgmMins(agmMins);
        setApiError(false);
      } catch (error) {
        console.log("error", error);
        setApiError(true);
        setCommitteeMins([]);
        setAgmMins([]);
      }
    } // end getFileUrl()

    // actually fetch the data
    console.log("fetching minutes");
    if (token !== "") {
      getMinutes();
    }
  }, [token]); // executes if the token chamges

  const classes = useStyles();
  return (
    <>
      <Box className={classes.title}>
        <Typography className={classes.titleText}>
          Army Golf Club - Committee Meeting Minutes
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            width: "50%",
          }}
        >
          {committeeMins.map((meeting, index) => {
            return (
              <div>
                <span>Minutes Committe Meeting - </span>
                <Box
                  key={index}
                  component={Link}
                  to={`/displayMinutes/${meeting.fileName}`}
                >
                  {meeting.dateString}
                </Box>
              </div>
            );
          })}
        </Box>
        <Box
          sx={{
            width: "50%",
          }}
        >
          {agmMins.map((meeting, index) => {
            return (
              <div>
                <span>Minutes AGM - </span>
                <Box
                  key={index}
                  component={Link}
                  to={`/displayMinutes/${meeting.fileName}`}
                >
                  {meeting.dateString}
                </Box>
              </div>
            );
          })}
        </Box>
      </Box>
    </>
  );
}

export default Minutes;
