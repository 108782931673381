/**
 * <Reports> displays a list of reports as read from Azure
 * It displays them as links to display the assocated PDF's
 * */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { getWithExpiry, setWithExpiry } from "../utils/localStorage";
import axios from "axios";
import dayjs from "dayjs";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);
var advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);

const useStyles = makeStyles((theme) => ({
  title: {
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      paddingBottom: "30px",
    },
  },
  titleText: {
    fontSize: "1em",
    [theme.breakpoints.up("md")]: {
      fontSize: "2em",
    },
  },
  subtitleText: {
    fontSize: ".7em",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.4em",
    },
  },
  headerText: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "1.5em",
    color: "green",
  },
  messageText: {
    fontSize: ".8em",
  },
}));

function Reports() {
  const [token, setToken] = useState(""); // the MSGraph token for the registered Azure App
  const [auditReps, setAuditReps] = useState([]);
  const [treasReps, setTreasReps] = useState([]);
  const [presReps, setPresReps] = useState([]);
  const [captReps, setCaptReps] = useState([]);
  const [apiError, setApiError] = useState(false);

  // Get an Azure access token if localStorage version is expired (60 mins)
  useEffect(() => {
    async function getToken() {
      let token = getWithExpiry("token");
      if (token) {
        setToken(token);
      } else {
        const response = await API.get("apiGetOneDriveToken", "/oneDriveToken");
        console.log("response: ", response);
        setToken(response.access_token);
        setWithExpiry("token", response.access_token, 60 * 58 * 1000); // 58 mins in ms
      }
    }
    getToken();
  }); // need it to run ever time becasue the token can expire in localStorage

  // Read the list of governance reports from Azure if not already read
  useEffect(() => {
    async function getReports() {
      const FILE_URL = `https://graph.microsoft.com/v1.0/drives/b!R4T0Vmiqb02DBArVNy0jBEg-mU0lOudPh_QV0PmucwVIYgffNZ_lR4uHD2E51qNV/root:/httpdocs/armygolfclubwa.com/members/reports:/children?$select=name,content.downloadUrl`;
      console.log("reports URL", FILE_URL);
      let res = {};
      let treasReports = [];
      let captReports = [];
      let auditReports = [];
      let presReports = [];
      try {
        res = await axios({
          method: "GET",
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
          url: FILE_URL,
        });
        console.log("res", res);
        for (const fileItem of res.data.value) {
          if (!fileItem.name.endsWith(".pdf")) {
            // skip unless a pdf
            continue;
          }
          if (fileItem.name.startsWith("AUDIT")) {
            let datePart = fileItem.name.slice(5, 9); // like YYYY
            let dateString = datePart;
            let sortField = parseInt(datePart);
            if (isNaN(sortField)) {
              continue;
            }
            auditReports.push({
              sortField,
              dateString,
              fileName: fileItem.name,
              url: fileItem["@microsoft.graph.downloadUrl"],
            });
          } else if (
            fileItem.name.startsWith("TREAS") &&
            fileItem.name.length === 15
          ) {
            let datePart = fileItem.name.slice(5, 11); // like YYMMDD
            let dateString = dayjs(datePart, "YYMMDD").format("Do MMM YYYY");
            let sortField = parseInt(datePart);
            if (isNaN(sortField)) {
              continue;
            }
            treasReports.push({
              sortField,
              dateString,
              fileName: fileItem.name,
              url: fileItem["@microsoft.graph.downloadUrl"],
            });
          } else if (
            fileItem.name.startsWith("PRES") &&
            fileItem.name.length === 12
          ) {
            let datePart = fileItem.name.slice(4, 8); // like YYYY
            let dateString = datePart;
            let sortField = parseInt(datePart);
            if (isNaN(sortField)) {
              continue;
            }
            presReports.push({
              sortField,
              dateString,
              fileName: fileItem.name,
              url: fileItem["@microsoft.graph.downloadUrl"],
            });
          } else if (fileItem.name.startsWith("CAPT")) {
            let datePart = "";
            let dateString = "";
            if (fileItem.name.length === 12) {
              datePart = fileItem.name.slice(4, 8);
              dateString = datePart;
            } else if (fileItem.name.length === 14) {
              datePart = fileItem.name.slice(4, 10);
              dateString = dayjs(datePart, "YYMMDD").format("Do MMM YYYY");
            }
            let sortField = parseInt(datePart);
            if (isNaN(sortField)) {
              continue;
            }
            captReports.push({
              sortField: parseInt(datePart),
              dateString,
              fileName: fileItem.name,
              url: fileItem["@microsoft.graph.downloadUrl"],
            });
          }
        }
        auditReports.sort((a, b) => b.sortField - a.sortField);
        setAuditReps(auditReports);
        treasReports.sort((a, b) => b.sortField - a.sortField);
        setTreasReps(treasReports);
        presReports.sort((a, b) => b.sortField - a.sortField);
        setPresReps(presReports);
        captReports.sort((a, b) => b.sortField - a.sortField);
        setCaptReps(captReports);
        setApiError(false);
        console.log("Audit reports", auditReports);
        console.log("Treas reports", treasReports);
        console.log("Pres reports", presReports);
        console.log("Capt reports", captReports);
      } catch (error) {
        console.log("error", error);
        setApiError(true);
        setAuditReps([]);
        setTreasReps([]);
        setPresReps([]);
        setCaptReps([]);
      }
    } // end getFileUrl()

    // actually fetch the data
    console.log("fetching reports");
    if (token !== "") {
      getReports();
    }
  }, [token]); // executes if the token chamges

  const classes = useStyles();
  return (
    <>
      <Box className={classes.title}>
        <Typography className={classes.titleText}>
          Army Golf Club - Governance Reports
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            width: "25%",
          }}
        >
          <h3>Audit Reports</h3>
          {auditReps.map((report, index) => {
            return (
              <div>
                <Box
                  key={index}
                  component={Link}
                  to={`/displayReports/${report.fileName}`}
                >
                  {report.dateString}
                </Box>
              </div>
            );
          })}
        </Box>
        <Box
          sx={{
            width: "25%",
          }}
        >
          <h3>Treasurer's Reports</h3>
          {treasReps.map((report, index) => {
            return (
              <div>
                <Box
                  key={index}
                  component={Link}
                  to={`/displayReports/${report.fileName}`}
                >
                  {report.dateString}
                </Box>
              </div>
            );
          })}
        </Box>
        <Box
          sx={{
            width: "25%",
          }}
        >
          <h3>Presidents's Reports</h3>
          {presReps.map((report, index) => {
            return (
              <div>
                <Box
                  key={index}
                  component={Link}
                  to={`/displayReports/${report.fileName}`}
                >
                  {report.dateString}
                </Box>
              </div>
            );
          })}
        </Box>
        <Box
          sx={{
            width: "25%",
          }}
        >
          <h3>Captains's Reports</h3>
          {captReps.map((report, index) => {
            return (
              <div>
                <Box
                  key={index}
                  component={Link}
                  to={`/displayReports/${report.fileName}`}
                >
                  {report.dateString}
                </Box>
              </div>
            );
          })}
        </Box>
      </Box>
    </>
  );
}

export default Reports;
