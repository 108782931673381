import React from "react";
import DisplayPDF from "../components/displayPDF.js";
//Note: All of these can be replaced by a single route,differing only in the flename
// passed to <DisplayPDF>. Was done this way initially to allows for possible differences
// but these did not eventuate.

// Start of public menu

// Login() has its own file in /routes

// ABout Our Club Submenu
export function AboutUs() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/documents/about.pdf"></DisplayPDF>
  );
}

export function InMemorium() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/documents/memorialpage.pdf"></DisplayPDF>
  );
}

// single menu items
export function Objectives() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/documents/objectives.pdf"></DisplayPDF>
  );
}

export function ContactUs() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/documents/contact.pdf"></DisplayPDF>
  );
}

export function MembershipApply() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/documents/application.pdf"></DisplayPDF>
  );
}

export function ComingEvents() {
  return <DisplayPDF fileName="reports/fixturescoming.pdf"></DisplayPDF>;
}

// Login() has its own file in /routes
// end of Public Menu

//Start of members menu
export function Notices() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/documents/notices.pdf"></DisplayPDF>
  );
}

//// Captains Submenu
//export function CaptainsReport() {
//  return <div>Captains Report</div>;
//}
//export function WeeklyReport() {
//  return <div>Weekly Report</div>;
//}

// About Golf Submenu
export function PastEvents() {
  return <DisplayPDF fileName="reports/fixturespast.pdf"></DisplayPDF>;
}
export function ClubChampionships() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/reports/champs.pdf"></DisplayPDF>
  );
}
export function OrderOfMerit() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/reports/oom.pdf"></DisplayPDF>
  );
}
export function Eclectic() {
  return <DisplayPDF fileName="armygolfclubwa.com/eclectic.pdf"></DisplayPDF>;
}

export function MatchPlay() {
  return <DisplayPDF fileName="documents/matchplay.pdf"></DisplayPDF>;
}
export function BentPutter() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/documents/bentputter.pdf"></DisplayPDF>
  );
}
export function MonthlyMedals() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/documents/monthlymedals.pdf"></DisplayPDF>
  );
}

// Members SubMenu
// MembersDetailsData() has its own file in /routes
export function MembersDetails() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/members/members.pdf"></DisplayPDF>
  );
}
export function CodeOfConduct() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/documents/conduct.pdf"></DisplayPDF>
  );
}

// Governance SubMenu
export function Constitution() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/documents/constitution.pdf"></DisplayPDF>
  );
}

export function ByLaws() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/documents/by-laws.pdf"></DisplayPDF>
  );
}
// export function Minutes() {
//   return <DisplayPDF fileName="documents/minutes.pdf"></DisplayPDF>;
//}

export function Reports() {
  return <DisplayPDF fileName="documents/reports.pdf"></DisplayPDF>;
}

// Rules Submenu
export function RulesExplained() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/documents/rulesexplained.pdf"></DisplayPDF>
  );
}
export function ChampionshipRules() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/documents/rules-club-champs.pdf"></DisplayPDF>
  );
}
export function MatchPlayRules() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/documents/rules-matchplay.pdf"></DisplayPDF>
  );
}
export function MedalOfMedalsRules() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/documents/rules-medalofmedals.pdf"></DisplayPDF>
  );
}
export function OrderOfMeritRules() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/documents/rules-oom.pdf"></DisplayPDF>
  );
}
export function EclecticRules() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/documents/rules-eclectic.pdf"></DisplayPDF>
  );
}

// Honours Board Submenu
export function OfficeBearersAndChampions() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/documents/board-officebearers.pdf"></DisplayPDF>
  );
}
export function AnnualAwards() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/documents/board-annualawards.pdf"></DisplayPDF>
  );
}
export function AnnualTrophies() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/documents/board-annualtrophies.pdf"></DisplayPDF>
  );
}
export function OtherBoards() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/documents/board-other.pdf"></DisplayPDF>
  );
}
// remaining single Items
export function MugsGallery() {
  return (
    <DisplayPDF fileName="armygolfclubwa.com/documents/mugsgallery.pdf"></DisplayPDF>
  );
}
//export function SiteMap() {
//  return <div>SiteMap</div>;
//}
// Logout() has its own file in /routes
