import React from "react";
import { useParams, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Box from "@material-ui/core/Box";
import DisplayPDF from "./displayPDF.js";

const useStyles = makeStyles((theme) => ({
  backButton: {
    display: "inline-block",
    textDecoration: "none",
    color: "black",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  backIcon: {
    verticalAlign: "-6px",
  },
}));

// The type of report can be either
// 1. The Captains report
// 2. The results report from
function DisplayReport({ reportType }) {
  const { fileName } = useParams(); //as appearing at the end of the URL
  const classes = useStyles();
  console.log("fileName ", fileName);
  let azureFilePath;
  switch (reportType) {
    case "Minutes": {
      azureFilePath = `armygolfclubwa.com/members/minutes/${fileName}`;
      break;
    }
    case "Reports": {
      azureFilePath = `armygolfclubwa.com/members/reports/${fileName}`;
      break;
    }
    default: {
      break;
    }
  }

  return (
    <Box>
      <Link // Link at top of page to navigate back to Reports
        to={`/Governance/${reportType}`}
      >
        <ArrowBackIcon className={classes.backIcon} />
        <span className={classes.backButton}> {`Back to ${reportType}`}</span>
      </Link>
      <DisplayPDF fileName={azureFilePath}></DisplayPDF>
      <Link // Link at bottom of page to navigate back to reports
        to={`/Governance/${reportType}`}
      >
        <ArrowBackIcon className={classes.backIcon} />
        <span className={classes.backButton}>{`Back to ${reportType}`}</span>
      </Link>
    </Box>
  );
}

export default DisplayReport;
