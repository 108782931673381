import React, { useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import LoggedInContext from "./loggedInContext.js";
import { menuPublic, menuMembers } from "../menus.js";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Logout from "../routes/logout.js";

//import styler from "stylerjs"; // to get and set css styles by classname

/**
 * Notes on Navigation
 * If a user is logged in we display the Members menu
 * Otherwise we display the Public menu
 * The menu items and submenus objects are defined in menus.js
 *
 * on a large screen the menu is displayed as one row
 * on smaller screens the menu is displayed in 2 rows
 * on very small screens an empty bar with the menu icon
 * when the menu icon is pressed a sidebar menu appears
 * when an item is selected or user hovers away the sidebar dissappears
 *
 * So <navbar> needs to be informed of the screen size
 * This we do with a hook.
 *
 *
 */

// makestyles automatically passes the current theme as parameter
const useStyles = makeStyles((theme) => ({
  navbarBox: {
    marginBottom: "3px",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },

  navbar: {
    backgroundColor: "#333",
    flexDirection: "column",
    textAlign: "left",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
      flexWrap: "nowrap",
      textAlign: "center",
    },
  },

  menuItem: {
    fontSize: "14px",
    color: "white",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "5px",
    flex: "1 1 0", //makes them all the same width
    [theme.breakpoints.up("md")]: {
      flex: "1 1 auto",
      fontSize: "16px",
    },
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "red",
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  subMenu: {
    fontSize: "14px",
    height: "100%",
    color: "white",
    flex: "1 1 0", //makes them all the same width
    [theme.breakpoints.up("md")]: {
      flex: "1 1 auto",
      fontSize: "16px",
    },
    "&:hover": {
      backgroundColor: "red",
      cursor: "pointer",
      "& $subMenuContent": { display: "block" },
    },
  },

  subMenuTitle: {
    display: "inline-block",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "5px",
    height: "auto",
    color: "white",
    textAlign: "center",
    textDecoration: "none",
  },

  subMenuIcon: {
    color: "white",
    paddingTop: "5px",
    paddingBottom: "5px",
  },

  subMenuContent: {
    position: "absolute",
    left: "4em",
    [theme.breakpoints.up("sm")]: {
      // makes sidebar menu
      left: "auto",
      paddingLeft: "0px",
    },
    display: "none",
    backgroundColor: "#555",
    minWidth: "150px",
    boxShadow: "0px 8px 16px 0px rgba(0,0,1,0.5)",
    zIndex: "1",
  },

  subMenuItem: {
    display: "block",
    padding: "5px 10px",
    color: "white",
    textAlign: "left",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "red",
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
}));

function Navbar() {
  // make the classes in JSS
  const classes = useStyles();

  // inititialised in App.js and set in login/logout
  const { loggedIn } = useContext(LoggedInContext);

  // build up the menu array here
  const menuItemsArray = [];
  let menuItemsCount = 0;

  function makeMenus(menuArray) {
    menuArray.forEach((menuItem) => {
      if (menuItem.component) {
        menuItemsArray.push(
          menuItem.component === "Logout" ? (
            <Logout key={uuidv4()} />
          ) : (
            <Link
              key={uuidv4()}
              to={`/${menuItem.component.replace(/\s/g, "")}`} //remove spaces
              className={classes.menuItem}
            >
              {menuItem.component}
            </Link>
          )
        );
      } else {
        // its a submenu
        let topLevelRoute = `/${menuItem.submenu.replace(/\s/g, "")}`; //remove spaces
        // first make the list of menu items as Links
        const itemsList = [];
        menuItem.submenuItems.forEach((menuItem) => {
          itemsList.push(
            <Link
              key={uuidv4()}
              to={`${topLevelRoute}/${menuItem.component.replace(/\s/g, "")}`}
              className={classes.subMenuItem}
            >
              {menuItem.component}
            </Link>
          );
        });
        // now push the list inside its submenu wrappers
        menuItemsArray.push(
          <div key={uuidv4()} className={classes.subMenu}>
            <div className={classes.subMenuTitle}>{menuItem.submenu}</div>
            <Icon style={{ fontSize: "16px" }}>expand_more</Icon>
            <div className={classes.subMenuContent}>{itemsList}</div>
          </div>
        );
      }
    });
    menuItemsCount = menuItemsArray.length;
  }

  // Make up the array correct menu (Menber or Public)
  // We divided the items into 2 lines which helps with responsiveness.
  // ie displaying in one row or two

  // make either the public menu or the members menu
  if (loggedIn.status) {
    makeMenus(menuMembers);
    console.log(`in navbar: logged in ${loggedIn.status} ${loggedIn.user}`);
  } else {
    makeMenus(menuPublic);
    console.log(`in navbar: logged in ${loggedIn.status}`);
  }

  return (
    <Box display="flex" className={classes.navbarBox}>
      <Box display="flex" className={classes.navbar}>
        {menuItemsArray.slice(0, Math.ceil(menuItemsCount / 2))}
      </Box>
      <Box display="flex" className={classes.navbar}>
        {menuItemsArray.slice(Math.ceil(menuItemsCount / 2), menuItemsCount)}
      </Box>
    </Box>
  );
}

export default Navbar;
