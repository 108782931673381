import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { handleAxiosError } from "../utils/handleAxiosError.js";
import ADDRESSES from "../IPandPorts.js";

/**
 * Notes:
 * <SelectMember> is rendered conditionally by <BookingSheet>
 * <SelectMember> is passed the list attendees alreadey registered
 * it reads all attendee from Mongo and blanks out the registered attendees
 * User then select from the list of unregistered =(all - registered)
 *
 * Currently both the list that is displayed by <Autocomplete> "memberList" and saved
 * in "attendees" as "firstName lastName"
 *
 * When a new name is being added to Mongo bookingList api "/addBookingSheetName"
 * is passed (by <BookingSheet>)
 *    data: {
 *          bookingSheetId: bookingData.bookingSheetId,
 *          firstName: "Jimmy",
 *          lastName: "Thelad",
 *         },
 */

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid black",
  },
  options: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  TextFieldRoot: {
    marginTop: "10px",
  },
}));

// NOTE: we cant use getenv with React - although we could load variable from a file
const ARMY_NODE_SERVER_HOST = ADDRESSES.IP;
const ARMY_NODE_SERVER_PORT = ADDRESSES.APIPort;

// Select member is passed the currently registered members "currentAttendees"
// and a callback "saveSelectedMember" to fire when a new member is selected
function SelectMember({ saveSelectedMember, currentAttendees }) {
  // This is the list of options displayed by <Autocomplete>
  const [memberList, setMemberList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false); // loading indicator
  const [toggle, setToggle] = useState("member"); // either select and member or enter a visitor
  const [visitorName, setVisitorName] = useState("");
  const [visitorGolfLink, setVisitorGolfLink] = useState("");
  const [golfLinkError, setGolfLinkError] = useState(true);
  const classes = useStyles();

  // Read the full list of active member from Mongo "members"
  useEffect(() => {
    setIsLoaded(false);

    // First define fetchData() function
    const fetchData = async () => {
      let members = [];
      try {
        // get the fixure from MongoDB
        console.log(`Getting members names from MongoDB`);
        const MEMBER_NAMES_URL = `http://${ARMY_NODE_SERVER_HOST}:${ARMY_NODE_SERVER_PORT}/members2/listNames`;
        members = await axios({
          method: "GET",
          withCredentials: true,
          url: MEMBER_NAMES_URL,
        });

        //Make a composite name like "Warren Howard" and save in memberList[]
        const fullNames = [];
        members.data.userArray.forEach((member) => {
          fullNames.push({
            memberName: `${member.firstName} ${member.lastName}`, //,
            // selected: false,
          });
        });
        //console.log("size:",fullNames.length,fullNames)
        setMemberList(fullNames);
        setIsLoaded(true);
      } catch (error) {
        handleAxiosError(error);
      } finally {
        console.log(members.data.message);
      }
    };
    // actually fetch the data
    console.log("<SelectMember> Fetching Data");
    fetchData();
  }, []);

  // this callback activates when a user changes selection
  function memberSelected(selectPlayer) {
    // memberName is either null or an object like {memberName:"some name"}
    // for a visitor it will be {{memberName:"(V) - some visname name"}}
    console.log("member Selected in memberSelected()", selectPlayer);
    if (selectPlayer) {
      saveSelectedMember(selectPlayer);
    } else saveSelectedMember({ memberName: "" }); // disables <SelectCart> "confirm" button
  }

  // This is called if visitor or member is toggled
  function handleRadioChange(event) {
    console.log("toggle change: value is now:", event.target.value);
    setToggle(event.target.value);
  }

  // save keystrokes from VisitorName TextField
  function saveVisitorName(event) {
    setVisitorName("(V) " + event.target.value);
  }

  // save keystrokes from GolfLink TextField
  function saveVisitorGolfLink(event) {
    let golfLink = event.target.value;
    setVisitorGolfLink(golfLink);
    // make sure the golf link is exactly 10 digit
    if (verifyGolfLink(golfLink)) {
      console.log("GolfLink is good");
      setGolfLinkError(false);
    } else {
      console.log("GolfLink is bad");
      setGolfLinkError(true);
    }
    console.log(`golfLink: ${golfLink}`);
  }

  function verifyGolfLink(golfLink) {
    if (golfLink.length === 10 && golfLink.match(/^\d{10}$/)) return true;
    return false;
  }

  return (
    <>
      {isLoaded ? (
        <Box display="flex" flexDirection="column">
          <Box width="260px">
            <FormControl size="small" style={{ paddingLeft: "10px" }}>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="member"
                name="radio-buttons-group"
                onChange={handleRadioChange}
              >
                <FormControlLabel value="member" control={<Radio size="small" />} label="Member" />
                <FormControlLabel
                  value="visitor"
                  control={<Radio size="small" />}
                  label="Visitor"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          {toggle === "member" ? (
            <Box>
              {/* display the member selection component */}
              <Autocomplete
                classes={{ option: classes.options }}
                id="combo-box"
                options={memberList}
                getOptionDisabled={(option) => {
                  if (
                    // this returns false if member is already selected, and if so is blanked out
                    currentAttendees.find(
                      (attendee) => attendee.memberName === option.memberName
                    ) === undefined
                  ) {
                    return false;
                  } else {
                    return true;
                  }
                }}
                getOptionLabel={(option) => option.memberName}
                style={{ width: 250 }}
                renderInput={(params) => (
                  <TextField {...params} label="Select member to book" variant="outlined" />
                )}
                size="small"
                // onChange signature: function(event: object, value: any)
                onChange={(event, value) => memberSelected(value)}
              />
            </Box>
          ) : (
            <Box display="flex" flexDirection="column">
              {/* display the visitor textfelds, golflink must be a 10 digit number */}
              <TextField
                classes={{ root: classes.TextFieldRoot }}
                onChange={saveVisitorName}
                fullWidth={true}
                id="outlined-basic"
                label="Name"
                variant="outlined"
                size="small"
              />
              <TextField
                classes={{ root: classes.TextFieldRoot }}
                error={golfLinkError}
                onChange={saveVisitorGolfLink}
                id="outlined-basic"
                size="small"
                label="GolfLink"
                variant="outlined"
                helperText="Must be 10 digits"
              />
              <Button
                size="small"
                variant="contained"
                disabled={visitorName.length < 10}
                onClick={() =>
                  memberSelected({
                    memberName: visitorName,
                    visitorGolfLink: verifyGolfLink(visitorGolfLink) ? visitorGolfLink : null,
                  })
                }
              >
                Done
              </Button>
            </Box>
          )}
        </Box>
      ) : null}
    </>
  );
}

export default SelectMember;
