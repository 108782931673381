  // Axios error handling - TODO - feedback to users
  export function handleAxiosError(error) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log("handling Axios Error");
    if (error.response) {
      console.log(error.response.data);
      // The request was made but no response was received
    } else if (error.request) {
      console.log("The request was made but no response was received");
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Something happened in setting up the request that triggered an Error");
    }
  }