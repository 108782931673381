/**
 * Here we define the IP address and port numbers for the web server and API server
 *
 */
const ADDRESSES = {
  IP: "localhost", //where the  web server and API server live
  PDFIP: "localhost",
  WebPort: "3000",
  APIPort: "4041", // (armyServer) change to 4041 if development -- 3000 production
};

export const BODY = {
  client_id: "3e6a7cf8-fa0a-4461-a21e-bc3091d63ae4",
  tenant: "f60512bf-2f67-4424-9907-50c05c589adc",
  client_secret: "azD8Q~pdMu5oH62ymNg817~ncU0~0ERNr7LnBckw",
  token:
    "eyJ0eXAiOiJKV1QiLCJub25jZSI6ImNxWmYwSHd0OUZDQXNCQzhaVFc4SlNwRE5qOXFWTVVSdWNuTGItSjZKdkEiLCJhbGciOiJSUzI1NiIsIng1dCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyIsImtpZCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyJ9.eyJhdWQiOiJodHRwczovL2dyYXBoLm1pY3Jvc29mdC5jb20iLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9mNjA1MTJiZi0yZjY3LTQ0MjQtOTkwNy01MGMwNWM1ODlhZGMvIiwiaWF0IjoxNjgyNDc1NDQ1LCJuYmYiOjE2ODI0NzU0NDUsImV4cCI6MTY4MjQ3OTM0NSwiYWlvIjoiRTJaZ1lEZ2VxOC9semRpU1phTXZjUEw1bVF2ckFBPT0iLCJhcHBfZGlzcGxheW5hbWUiOiJnb2xmIiwiYXBwaWQiOiIzZTZhN2NmOC1mYTBhLTQ0NjEtYTIxZS1iYzMwOTFkNjNhZTQiLCJhcHBpZGFjciI6IjEiLCJpZHAiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9mNjA1MTJiZi0yZjY3LTQ0MjQtOTkwNy01MGMwNWM1ODlhZGMvIiwiaWR0eXAiOiJhcHAiLCJvaWQiOiI4NTRlY2ZmMC1hMGUzLTQwYzItODY5Zi1mY2IwNzI0YTI5MjgiLCJyaCI6IjAuQVVJQXZ4SUY5bWN2SkVTWkIxREFYRmlhM0FNQUFBQUFBQUFBd0FBQUFBQUFBQUJDQUFBLiIsInJvbGVzIjpbIlVzZXIuUmVhZFdyaXRlLkFsbCIsIlBlb3BsZS5SZWFkLkFsbCIsIkFwcGxpY2F0aW9uLlJlYWRXcml0ZS5BbGwiLCJEaXJlY3RvcnkuUmVhZFdyaXRlLkFsbCIsIkZpbGVzLlJlYWRXcml0ZS5BbGwiLCJTaXRlcy5GdWxsQ29udHJvbC5BbGwiXSwic3ViIjoiODU0ZWNmZjAtYTBlMy00MGMyLTg2OWYtZmNiMDcyNGEyOTI4IiwidGVuYW50X3JlZ2lvbl9zY29wZSI6Ik9DIiwidGlkIjoiZjYwNTEyYmYtMmY2Ny00NDI0LTk5MDctNTBjMDVjNTg5YWRjIiwidXRpIjoidHZhOXZ5VXg1VTZyNkFUVW9xRVBBQSIsInZlciI6IjEuMCIsIndpZHMiOlsiMDk5N2ExZDAtMGQxZC00YWNiLWI0MDgtZDVjYTczMTIxZTkwIl0sInhtc190Y2R0IjoxNjQxMTk5ODQ4fQ.K6PpafobyAb9I0XSCvEEfN1JdObESqDgpREG4XxBHpOt7C8p9h9W2vbrm_iSWM6SbQ7SjJVrB3wEY3I2-coKvRYlvh1d5rOU791q3VeXdbeC5c0SlUYVaB9iq4gjeSicMhFIqHi1AhDyfUbjMjIuEqZ_7InOfK5DbxuRA0ab05ST1g8lpDqqEvUS9O9JCV1jKew9Zt_ddJTBUqWaqbHEa_xSqBPLQ62ENsMg7lsxYylDVdkmn9XubuHro5BGhHIfV2NM1cEpc6AQzzpZ7rdqcYkVx7mB0tzLGy_twhn855tLet8Str-gby8j_bJ4uXT53vcBiW4qB2Xlc9wu2oWfWQ",
};
/**
 * This is the contents of IPandPorts.js on the Linode machine
 *
 */
//  const ADDRESSES = {
//   IP: "www.army-wa.club",   //where the  web server and API server live
//   PDFIP:"www.army-wa.club",
//   WebPort: "80",  //was 3000 but nginx  only listens on 80 and reroutes to 3000
//   APIPort: "80",  // ditto
//};

export default ADDRESSES;
