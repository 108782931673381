import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import Navbar from "./navbar";

/**
 * navController
 * This component's role is to choose which menu to display
 *
 * Notes on Navigation
 * If a user is logged in we display the Members menu
 * Otherwise we display the Public menu
 * The menu items and submenus objects are defined in menus.js
 *
 * On a large screen the menu <NavBar> is displayed as one row
 * On a medium screens the menu <NavBar> is displayed in 2 rows
 * On small screens an empty bar with the menu icon <MobileBar> is displayed
 * When the menu icon is pressed a sidebar menu <SideBar> appears
 *    It is arranged vertically and positioned absolutely
 *    When the user hovers away the sidebar dissappears
 *
 * So <NavbarController> needs to be informed of the screen size
 * This we do with a hook useMediaQuery()
 *
 * In summary we just display <Navber> for medium and large screens
 * For small screens we display <MobileBar> which in turn displays
 * <SideBar>
 * <SideBar> is a wrapped <Navbar> to allow absolute positioning
 * and onMouseLeave closing event
 */
// makestyles automatically passes the current theme as parameter
const useStyles = makeStyles((theme) => ({
  menuIconBox: {
    justifyContent: "flex-end",
    position: "relative",
  },
  menuIcon: {
    color: "white",
  },
  menuIconRoot: {
    padding: "3px",
    fontSize: "3em",
  },
  sidebarContainer: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "50%",
    zIndex: "2",
  },
}));

// make the mobile menu bar
function MobileBar() {
  const [sideBarOpen, setSideBarOpen] = useState(false);

  // define classes in JSS
  const classes = useStyles();

  function handleToggleSideBar() {
    sideBarOpen ? setSideBarOpen(false) : setSideBarOpen(true);
  }

  return (
    <>
      <Box display="flex" className={classes.menuIconBox}>
        {sideBarOpen ? <SideBar closeFn={handleToggleSideBar}></SideBar> : null}
        <IconButton
          classes={{ root: classes.menuIconRoot }}
          className={classes.menuIcon}
          aria-label="menu"
          onClick={handleToggleSideBar}
        >
          <MenuIcon fontSize="inherit" />
        </IconButton>
      </Box>
    </>
  );
}

// make a sidebar that is positioned absolutely
function SideBar({ closeFn }) {
  // define classes in JSS
  const classes = useStyles();

  return (
    <Box className={classes.sidebarContainer} onMouseLeave={closeFn}>
      <Navbar></Navbar>
    </Box>
  );
}

function NavController() {
  // check if the screen is mobile size
  const theme = useTheme();
  const screenIsBig = useMediaQuery(theme.breakpoints.up("sm")); // true for large screens

  /**
   * The logic is this:
   * If the screen is small use <MobileBar>
   * Otherwise use <Navbar>
   * <NavBar> can work out which menu and config
   * */
  return screenIsBig ? <Navbar /> : <MobileBar />;
}

export default NavController;
