import React, { useContext, useState, useEffect } from "react";
import { API } from "aws-amplify";
import { getWithExpiry, setWithExpiry } from "../utils/localStorage.js";
import { makeStyles } from "@material-ui/styles";
import LoggedInContext from "../components/loggedInContext.js";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  homeContainer: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      paddingBottom: "30px",
    },
  },
  titleText: {
    fontSize: "1em",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2em",
    },
  },
  photo: {
    width: "100%",
  },
}));

function Home() {
  const [token, setToken] = useState("");
  const [MSGraphUrl, setMSGraphUrl] = useState(""); // the MSGraph url
  const [apiError, setApiError] = useState(false);

  const classes = useStyles();
  // Get an Azure access token if localStorage version is expired (60 mins)
  useEffect(() => {
    async function getToken() {
      let token = getWithExpiry("token");
      if (token) {
        setToken(token);
      } else {
        const response = await API.get("apiGetOneDriveToken", "/oneDriveToken");
        console.log("response: ", response);
        setToken(response.access_token);
        setWithExpiry("token", response.access_token, 60 * 58 * 1000); // 58 minutes
      }
    }
    getToken();
  }); // need it to always run becasue the token can expire

  useEffect(() => {
    async function getFileUrl(fileName) {
      const FILE_URL = `https://graph.microsoft.com/v1.0/drives/b!R4T0Vmiqb02DBArVNy0jBEg-mU0lOudPh_QV0PmucwVIYgffNZ_lR4uHD2E51qNV/root:/httpdocs/${fileName}:/`;
      console.log("file url", FILE_URL);
      let res = {};
      try {
        res = await axios({
          method: "GET",
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
          url: FILE_URL,
        });
        console.log("url", res["data"]["@microsoft.graph.downloadUrl"]);
        setMSGraphUrl(res["data"]["@microsoft.graph.downloadUrl"]);
      } catch (error) {
        console.log("error", error);
        setApiError(true);
      }
    } // end getFileUrl()

    // actually fetch the data
    console.log("fetching file URL");
    if (token !== "") {
      getFileUrl("armygolfclubwa.com/graphics/background2.png");
    }
  }, [token]); // executes if the token or filename changes

  return (
    <Box className={classes.homeContainer}>
      <Box className={classes.title}>
        <Typography className={classes.titleText}>
          Welcome to Army Golf Club WA
        </Typography>
      </Box>
      {!apiError ? (
        <Box>
          <img className={classes.photo} src={MSGraphUrl} alt="members"></img>
        </Box>
      ) : (
        <Box>Loading Image ....</Box>
      )}
    </Box>
  );
}

export default Home;
